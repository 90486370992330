import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";

//AWS
import { API, graphqlOperation, Auth } from "aws-amplify";
import { createAliados, createAliadosCiudades, updateAliados, deleteAliadosCiudades } from "./../../../../../../../graphql/mutations";
import { getAliados } from "./../../../../../../../graphql/queries";

const useStyles = makeStyles((theme) => ({
  TextFieldMultinea: {
    width: "100%",
    border: "1px solid #63BAB3",
    borderRadius: 11,
    "& > div::before": {
      display: "none",
    },
    "& > div::after": {
      display: "none",
    },
    "& > label.MuiInputLabel-shrink": {
      transform: "translate(0, 16px) scale(0.75)",
    },
  },
  TextFieldMultineaError: {
    width: "100%",
    border: "1px solid #ff5858",
    borderRadius: 11,
    "& > div::before": {
      display: "none",
    },
    "& > div::after": {
      display: "none",
    },
    "& > label.MuiInputLabel-shrink": {
      transform: "translate(0, 16px) scale(0.75)",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: "blur(4px)",
  },
  paper: {
    backgroundColor: "white",
    borderRadius: 12,
    width: 368,
    paddingTop: 32,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 32,
  },
}));


let formInit = {
  razon: "",
  tipo_documento: "",
  numero_documento: "",
  telefono: "",
  correo: "",
  ciudad: "",
  estado: true,
  informacion_adicional: "",
  aliadosCiudades: [],
};

function FormAliado({ ciudadesAliados, checkedCobertura, setCiudadesAliados, setCheckedCobertura}) {
  const classes = useStyles();
  const [formState, setFormState] = useState(formInit);
  const [ciudadesIniciales, setCiudadesIniciales] = useState([]);
  const [cargando, setCargando] = useState(false);
  const [redirigir, setRedirigir] = useState(false);
  const [open, setOpen] = useState(false);
  const [verState, setVerState] = useState(false);
 
  useEffect(() => {
    validateSession()
    getIdAliado()
  }, []);

  const getIdAliado = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const idObject = Object.fromEntries(urlSearchParams.entries());
    const nLength = Object.keys(idObject).length;  

    if (nLength > 0) {
      setVerState(true);
      let idAliado = idObject.id;
      let ciudadesGetAliado = [];
      await API.graphql(
        graphqlOperation(getAliados, { id: idAliado })
      ).then(result => {
        setFormState(result.data.getAliados);
        setCiudadesIniciales(result.data.getAliados.aliadosCiudades.items)
        result.data.getAliados.aliadosCiudades.items.map(a => {
          ciudadesGetAliado.push(a.ciudad)
        })
        setCiudadesAliados(ciudadesGetAliado)
        setCheckedCobertura(result.data.getAliados.cobertura_nacional)
      }).catch(a => {
        console.log('Error', a)
      });
      
    } else {
    }
  };

  function validateSession() {
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
    .then((user) => {
      console.log("Usuario Conectado");
    })
    .catch((err) => setRedirigir(true));
  }

  function setInput({ target }) {
    const { name, value } = target;
    setFormState({ ...formState, [name]: value });
  }

  function setInputBlur({ target }) {
    const { name, value } = target;
    setFormState({ ...formState, [name]: value.trim() });
  }

  function validarFormulario() {
    const expr = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    for (let key in formState) {
      if (key !== 'cobertura_nacional' && !formState[key]) {
        return false;
      }
    }
    if (!expr.test(formState["correo"])) {
      return false;
    }
    return true;
  }

  async function crearAliado() {
    
    setCargando(true);
    setFormState({ ...formState, submit: true, estado:true });
    let usuario;
    await Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
    .then((user) => {
      usuario = user;
    })
    .catch((err) => alert("Deberia de Iniciar Sesion"));
    let aliadoCreada;

    if (validarFormulario()) {
      if(verState){
        //Actualizar        
        const urlSearchParams = new URLSearchParams(window.location.search);
        const idObject = Object.fromEntries(urlSearchParams.entries());
        
        let sendDataAliadoUpdate = {
          query: updateAliados,
          variables:{
            input: {
              razon: formState.razon.toUpperCase(),
              tipo_documento: formState.tipo_documento,
              numero_documento: formState.numero_documento,
              ciudad: formState.ciudad,
              telefono: formState.telefono,
              correo: formState.correo,
              informacion_adicional: formState.informacion_adicional,
              estado: true,
              cobertura_nacional: checkedCobertura,
              id: idObject.id
            }
          },
        };
        await API.graphql(sendDataAliadoUpdate)
        .then((result) => {
          aliadoCreada = result.data.updateAliados;
          setCiudadesIniciales(result.data.updateAliados.aliadosCiudades.items)
        })
        .catch((err) => {
          console.log("error sede", err);
          alert("Ocurrio un error");
        });

        const ciudadesAliadosSendDelete = await Promise.all(
          ciudadesIniciales.map(async (ciudad) => {
            const todoDetails = {
              id: ciudad.id,
            };
            let qq = await API.graphql({ query: deleteAliadosCiudades, variables: {input: todoDetails}});
          })
        )

      }else{
        let sendDataAliado = {
          input: {
            razon: formState.razon.toUpperCase(),
            tipo_documento: formState.tipo_documento,
            numero_documento: formState.numero_documento,
            ciudad: formState.ciudad,
            telefono: formState.telefono,
            correo: formState.correo,
            informacion_adicional: formState.informacion_adicional,
            estado: true,
            cobertura_nacional: checkedCobertura
          },
        };
        
        await API.graphql(graphqlOperation(createAliados, sendDataAliado))
        .then((result) => {
          aliadoCreada = result.data.createAliados;
        })
        .catch((err) => {
          console.log("Error de la operacion de creacion ", err);
          setCargando(false);
        });          
      }

      //GuardarCiudadesAliados
      const ciudadesAliadosSend = await Promise.all(
        ciudadesAliados.map(async (ciudad) => {
          let sendDataCiudadAliado = {
            input: {
              ciudad: ciudad,
              aliadoID: aliadoCreada.id,
            },
          };
          await API.graphql(graphqlOperation(createAliadosCiudades, sendDataCiudadAliado))
        })
      )
      setCargando(false);
      handleOpen();
    } else {
      setCargando(false);
    }
    if (redirigir) {
      return <Redirect to="/admin/aliados" />;
    }
  }  

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setRedirigir(true)
    return <Redirect to="/admin/aliados" />;
  };

  if (redirigir) {
    return <Redirect to="/admin/aliados" />;
  }

  return (
    <div className="w-100 h-100">
      <div className="wrapper-general pt-4 position-relative mb-28">
        <div className="d-flex jusitfy-content-between align-items-center ">
          <Link
            to="/admin/aliados"
            className="btn-finger fz-24 text-blue-ligth position-absolute"
          >
            <i className="fal fa-chevron-left"></i>
          </Link>
          <h2 className="text-dark-1 fz-28 fw-700 title-business text-center w-100">
            {verState ? "Información del Aliado" : "Crear nuevo aliado"}
          </h2>
        </div>
      </div>

      <div className="w-100 bg-blue-ligth-2 mb-20">
        <div className="wrapper-general ">
          <h2 className="text-blue fw-700 fz-20 py-12 mb-0">
            Información del aliado
          </h2>
        </div>
      </div>

      <div className="wrapper-general mb-20">
        <div className="row mx-0">
          <div className="col-lg-6 col-md-12 col-sm-12 px-2 mb-20">
            <TextField
              id="razon"
              name="razon"
              label="Nombre/Razón Social"
              className={`text-fiel-custom-blue ${
                !formState["razon"] && formState["submit"]
                  ? "text-fiel-error"
                  : ""
              }`}
              onChange={setInput}
              onBlur={setInputBlur}
              value={formState.razon}
            />
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 px-2 mb-20">
            <TextField
              id="tipoDocumento"
              name="tipo_documento"
              select
              label="Tipo de documento"
              className={`text-fiel-custom-blue ${
                !formState["tipo_documento"] && formState["submit"]
                  ? "text-fiel-error"
                  : ""
              }`}
              onChange={setInput}
              value={formState.tipo_documento}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <KeyboardArrowDownIcon />
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value={1}>T.I.</MenuItem>
              <MenuItem value={2}>C.C.</MenuItem>
              <MenuItem value={3}>NIT</MenuItem>
            </TextField>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 px-2 mb-20">
            <TextField
              id="numero_documento"
              name="numero_documento"
              label="Número de documento"
              className={`text-fiel-custom-blue ${
                !formState["numero_documento"] && formState["submit"]
                  ? "text-fiel-error"
                  : ""
              }`}
              type="number"
              onChange={setInput}
              onBlur={setInputBlur}
              value={formState.numero_documento}
            />
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 px-2 mb-20">
            <TextField
              id="ciudad"
              name="ciudad"
              label="Ciudad"
              className={`text-fiel-custom-blue ${
                !formState["ciudad"] && formState["submit"]
                  ? "text-fiel-error"
                  : ""
              }`}
              onChange={setInput}
              onBlur={setInputBlur}
              value={formState.ciudad}
            />
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 px-2 mb-20">
            <TextField
              id="contacto"
              name="telefono"
              label="Contacto"
              className={`text-fiel-custom-blue ${
                !formState["telefono"] && formState["submit"]
                  ? "text-fiel-error"
                  : ""
              }`}
              type="number"
              onChange={setInput}
              onBlur={setInputBlur}
              value={formState.telefono}
            />
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 px-2 mb-20">
            <TextField
              id="correo"
              name="correo"
              label="Correo electrónico"
              className={`text-fiel-custom-blue ${
                !formState["correo"] && formState["submit"]
                  ? "text-fiel-error"
                  : ""
              }`}
              onChange={setInput}
              onBlur={setInputBlur}
              value={formState.correo}
              pattern="/^([\da-z_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/"
            />
          </div>
        </div>
      </div>

      <div className="w-100 bg-blue-ligth-2 mb-20">
        <div className="wrapper-general ">
          <h2 className="text-blue fw-700 fz-20 py-12 mb-0">
            Información adicional
          </h2>
        </div>
      </div>

      <div className="wrapper-general">
        <div className="row mx-auto">
          <div className="col-md-12 mb-32">
            <TextField
              id="informacionAdicional"
              name="informacion_adicional"
              className={
                !formState["informacion_adicional"] && formState["submit"]
                  ? classes.TextFieldMultineaError
                  : classes.TextFieldMultinea
              }
              multiline
              rows={8}
              onChange={setInput}
              onBlur={setInputBlur}
              value={formState.informacion_adicional}
            />
          </div>
        </div>
      </div>

      <div className="w-100 bg-blue-ligth-2">
        <div className="container border-top-gray">
          <div className="row mx-auto justify-content-lg-between justify-content-center pt-28 pb-32">
            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 mb-2">
              <button
                className="btn-custom bg-blue-dark text-white fz-16 fw-700 position-relative "
                onClick={() => setRedirigir(true)}
              >
                <div className="btn-finger text-white fz-24 icon-button-start">
                  <i className="fal fa-times"></i>
                </div>
                CANCELAR
              </button>
            </div>
            <div className="col-xl-6 col-lg-8 col-md-8 col-sm-12 mb-2">
              <button
                className="btn-custom bg-yellow text-white fz-16 fw-700 position-relative "
                onClick={crearAliado}
              >
                <div className="btn-finger text-white fz-24 icon-button-start">
                  <i
                    className={`fal ${verState ? "fa-save" : "fa-user-hard-hat"}`}
                  ></i>
                </div>
                {cargando ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                ) : verState ? (
                  "GUARDAR CAMBIOS"
                ) : (
                  "CREAR ALIADO"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div
              className="mb-12 text-blue-ligth w-100 d-flex justify-content-center"
              style={{ fontSize: 72 }}
            >
              <i className="fal fa-user-hard-hat"></i>
            </div>
            <h2 className="fz-28 fw-700 text-dark-1 text-center mb-12">
              Aliado registrado!
            </h2>
            <p className="text-center text-dark-1">
              El nuevo contratista ha sido registrado exitosamente
            </p>
            <div className="row mx-auto d-flex justify-content-center">
              <div className="col-md-7">
                <button
                  className="btn-custom bg-yellow text-white fz-16 fw-700 position-relative "
                  onClick={handleClose}
                >
                  ENTENDIDO
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
export { FormAliado };
