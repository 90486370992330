import { useState, useEffect,Fragment } from "react";
import { Link } from 'react-router-dom';
import "./Preferences.css";

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import {  Snackbar } from "@material-ui/core";

import InputAdornment from "@material-ui/core/InputAdornment";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MuiAlert from "@material-ui/lab/Alert";

//AWS
import { API, Auth, graphqlOperation } from "aws-amplify";
import {  getEmpresa } from "./../../../graphql/queries";
import { createPreferencias } from "./../../../graphql/mutations";


const currencies = [];

const currencies2 = [];

const initialPreferences = {
  enQueCiudadEstaTuSede: '',
  cualEsTuSede: '',
}


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },

  TextFieldCustom: {
    width: "100%",
    margin: "15px 0 15px 0",
    "& > label": {
      color: "63BAB3#",
    },
    "& > div::before": {
      borderBottom: "1px solid #63BAB3",
    },
  },
}));

const Preferences = () => {
  const [comboBox, setComboBox] = useState(initialPreferences);
  const [ciudadesState, setCiudadesState] = useState([]);
  const [sedesState, setSedesState] = useState([]);
  const [usuarioState, setUsuarioState] = useState({});
  const [open, setOpen] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [msgError, setMsgError] = useState("");
  const handleClose = () => setOpen(false);
  //let usuario = '';

  useEffect(() => {
    getSedes();
  }, []);

  const getSedes = async () => {   
    let usuario
    await Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
    .then((user) => {
      setUsuarioState(user)
      usuario = user;
    })
    .catch((err) => alert("Deberia de Iniciar Sesion"));
    
    let empresa = usuario.attributes['custom:empresa'];
    //Get Sede principal de la empresa 
    const empresaData = await API.graphql(
      graphqlOperation(getEmpresa, { id: empresa })
    ).then(r => {
      //setEmpresaState(r.data.getEmpresa);      
      r.data.getEmpresa.sedes.items.map(s => {
        if(s.estado == true){
          currencies.push({
            value: s.ciudad,
            label: s.ciudad,
          })
  
          currencies2.push({
            value: s.id,
            label: s.nombre,
          });
        }        
      });
      
      setCiudadesState(currencies.sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0)))

      setSedesState(currencies2.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)))      
    });
  }


  const handleChange = (key, value) => {
    setComboBox({ ...comboBox, [key]: value });
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  } 

  const guardarPreferencia = async () => {
    if(comboBox.enQueCiudadEstaTuSede !== '' && comboBox.cualEsTuSede !== ''){
      let sendDataPreferencia = {
        input: {
          ciudad: comboBox.enQueCiudadEstaTuSede,
          sede: comboBox.cualEsTuSede,
          usuario: usuarioState.username
        },
      };
      await API.graphql(graphqlOperation(createPreferencias, sendDataPreferencia))
        .then((result) => {
          setMsgError("Registrado Correctamente");
          setOpen(true);
        })
        .catch((err) => {
          console.log("Error de la operacion de creacion ", err);
        });
    }    
  }

  return (
    <Fragment>
      <div className="container-home">
        <div className="d-flex justify-content-center flex-column">
          <h5 className="text-start mt-40 mb-16 fs-24 fw-700 letter-s-1-2 text-dark-2">Mis preferencias</h5>
          <p className="text-start fs-16 letter-s-0 color-dark-2 mb-24">Selecciona la ciudad y la sede en la cual trabajas regularmente.</p>
          
          <form className="f-flex mb-52" noValidate autoComplete="off">
            <TextField
              id="combo1"
              select
              className="text-fiel-custom-blue mb-20"
              label="¿En qué ciudad esta tu sede?"
              value={comboBox.enQueCiudadEstaTuSede}
              onChange={(event) => handleChange("enQueCiudadEstaTuSede", event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <KeyboardArrowDownIcon />
                  </InputAdornment>
                ),
              }}
            >
              {ciudadesState.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
              
            </TextField>
            
            <TextField
              id="combo2"
              select
              className="text-fiel-custom-blue mb-20"
              label="¿Cual es tu sede?"
              value={comboBox.cualEsTuSede}
              onChange={(event) => handleChange("cualEsTuSede", event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <KeyboardArrowDownIcon />
                  </InputAdornment>
                ),
              }}
            >
              {sedesState.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </form>

          <Link to="#">
            <button onClick={guardarPreferencia} type="button" className="btn bg-yellow-0  bs-2 br-30 color-white-1 fs-14 w-100-p fw-bold py-13 mb-16">GUARDAR</button>
          </Link>
                
          <Link to="/cliente/home">
            <button type="button" className="btn btn-cerrar bg-grey-0 bs-2  br-30 color-white-1 mh-48 fs-14 w-100-p  fw-bold py-13 mb-16">ATRAS</button>
          </Link>
        </div> 
        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              {msgError}
            </Alert>
        </Snackbar>
      </div>
  </Fragment>
  );
}
export default Preferences;