import { Fragment } from "react";
import Navbar from "../layoutcli/Navbarcli";

const Layoutcli = ({ children }) => {
  return (

    
/*   useEffect(() => {
    if (window.location.pathname=='/cliente/home') {
      alert(window.location.pathname);
    }
  });
 */
    <Fragment>
      <div className="background-layout">
        <div className="wrapper-lazy">
          <div className="bg-white w-100-p h-100-vh box-shadow-3 pt-56">
          <Navbar />
          {children}
        </div>
      </div>
    </div>
    </Fragment>
  );
};

export default Layoutcli;