import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

//CSS
import "./Aliados.css";

//Material UI
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";

//AWS
import { listAliados } from "./../../../graphql/queries";
import { updateAliados } from "./../../../graphql/mutations";
import { API, Auth } from "aws-amplify";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#FFFFFF",
    color: "#707070",
    fontWeight: "700",
    height: "56px",
    background: "#FFFFFF",
    fontSize: 16,
    border: 0,
  },
  body: {
    fontSize: 16,
    color: "#707070",
    border: 0,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F5FCFF",
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    width: "100%",
    boxShadow: "0",
  },
  tableHead: {
    padding: 72,
  },
  tableBody: {
    padding: 72,
  },
  tdId: {
    paddingLeft: 72,
  },
  container: {
    maxHeight: 700,
    minHeight: 700,
  },
  buttonEdit: {
    color: "#63bab3",
    fontSize: 24,
  },
  buttonDelete: {
    color: "#ff5858",
    fontSize: 24,
  },
  buttonLink: {
    textDecoration: "none",
  },
});

const documentos = {1:'T.I.',2:'C.C.',3:'NIT'}
const initFiltro = {}
initFiltro.estado = {};
initFiltro.estado.eq = true;

function Aliados(props) {
    // Buscador
  const [filtro, setFiltro] = useState(initFiltro);
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState();
  const [previousTokens, setPreviousTokens] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [aliados, setAliados] = useState([]);
  const limit = 10;
  const [aliadoSearch, setAliadoSearch] = useState("");
  const [aliadoDocSearch, setAliadoDocSearch] = useState("");

  useEffect(() => {    
    validateSession()
    getList();
  }, [nextToken, filtro]);

  function validateSession() {
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
    .then((user) => {
      console.log("Usuario Conectado");
    })
    .catch((err) => props.history.push("/admin/login"));
  }

  async function getList() {
    setCargando(true);
    try {
      const listadoAliadosData = await API.graphql({
        query: listAliados,
        variables: {
          limit,
          nextToken,
          filter: filtro,
        },
      });
      let dataItem = listadoAliadosData.data.listAliados.items;
      setAliados(dataItem);
      setNextNextToken(listadoAliadosData.data.listAliados.nextToken);
    } catch (err) {
      console.log(err);
    } finally {
      setCargando(false);
    }
  }

  async function nextPage() {
    setPreviousTokens((prev) => [...prev, nextToken]);
    setNextToken(nextNextToken);
    setNextNextToken(null);
  }

  function prevPage() {
    setNextToken(previousTokens.pop());
    setPreviousTokens([...previousTokens]);
    setNextNextToken(null);
  }

  function search() {
    console.log("a buscar", aliadoSearch);
    console.log("a buscar", aliadoDocSearch);
    let filtrar = {};
    filtrar.estado = {};
    filtrar.estado.eq = true;

    if (aliadoSearch !== undefined && aliadoSearch !== "") {
      filtrar = filtrar === undefined ? {} : filtrar;
      filtrar.razon = {};
      filtrar.razon.contains = aliadoSearch.toUpperCase();
    }

    if (aliadoDocSearch !== undefined && aliadoDocSearch !== "") {
      filtrar = filtrar === undefined ? {} : filtrar;
      filtrar.numero_documento = {};
      filtrar.numero_documento.contains = aliadoDocSearch.toUpperCase();
    }

    setFiltro(filtrar);
  }

  const deleteAliado = async (id) => {
    let sendData = {
      query: updateAliados,
      variables: {
        input: {
          id: id,
          estado: false
        },
      },
    };
    await API.graphql(sendData)
      .then((result) => {
        console.log("Se Cambia de estado", result);
        getList();
      })
      .catch((err) => {
        console.log("error sede", err);
        alert("Ocurrio un error");
      });
  }

  const classes = useStyles();

  return (
    <Fragment>
      <div className="w-100 box-shadow position-relative header-business">
        <div className="wrapper-general pb-28 pt-32">
          <div className="row mx-auto mb-32">
            <div className="col-lg-3 col-md-6 d-flex align-items-center px-0">
              <Link to="/admin" className="btn-finger fz-24 text-blue-ligth">
                <i className="fal fa-chevron-left"></i>
              </Link>
              <h2 className="text-dark-1 fz-28 fw-700 title-business">
                Aliado
              </h2>
            </div>
            <div className="col-lg-3 col-md-6 px-0">
              <Link
                to="/admin/crear-aliados"
                className="button-create-business justify-content-start fw-700"
              >
                <button className="btn-finger  text-blue-ligth">
                  <i className="fal fa-plus fz-24"></i>
                </button>
                Crear Nuevo Aliado
              </Link>
            </div>
          </div>

          <div className="row d-flex align-items-center">
            <div className="mb-2 col-md-6 col-lg-6 col-xl-5">
              <TextField
                id="search"
                label="Criterio de búsqueda"
                className="text-fiel-custom-blue"
                value={aliadoSearch}
                onChange={(event) => setAliadoSearch(event.target.value)}
              />
            </div>
            <div className="mb-2 col-md-6 col-lg-6 col-xl-5">
              <TextField
                id="documento"
                label="Número de documento"
                className="text-fiel-custom-blue"
                value={aliadoDocSearch}
                onChange={(event) => setAliadoDocSearch(event.target.value)}
              />
            </div>
            <div className="mb-2 col-md-4 col-lg-4 col-xl-2">
              <button className="btn-custom d-flex justify-content-center bg-yellow text-white box-shadow-1 fw-700 position-relative position-relative" onClick={search}>
                <div className="btn-finger text-white fz-24 icon-button-start">
                  <i className="fal fa-search"></i>
                </div>
                {cargando ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                ) : (
                  "BUSCAR"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100  px-0 position-relative list-business">
        <TableContainer component={Paper} className={classes.container}>
          <Table
            className={classes.table}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead className={classes.tableHead}>
              <TableRow>
                <StyledTableCell className={classes.tdId}>
                  Nombre/Razón Social
                </StyledTableCell>
                <StyledTableCell>Tipo de documento</StyledTableCell>
                <StyledTableCell>Número de documento</StyledTableCell>
                <StyledTableCell>Contacto</StyledTableCell>
                <StyledTableCell>Correo electrónico</StyledTableCell>
                <StyledTableCell>Ciudad</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {aliados.map((row, b) => (
                <StyledTableRow key={b}>                 
                  <StyledTableCell className={classes.tdId}>
                    {row.razon}
                  </StyledTableCell>
                  <StyledTableCell>{documentos[row.tipo_documento]}</StyledTableCell>
                  <StyledTableCell>{row.numero_documento}</StyledTableCell>
                  <StyledTableCell>{row.telefono}</StyledTableCell>
                  <StyledTableCell>{row.correo}</StyledTableCell>
                  <StyledTableCell>{row.ciudad}</StyledTableCell>
                  <StyledTableCell>
                    <div className="d-flex justify-content-center align-items-center">
                      <Link
                        className={classes.buttonLink}
                        to={`/admin/ver-aliados/?id=${row.id}`}
                      >
                        <Button className={classes.buttonEdit}>
                          <i className="fal fa-eye fz-24"></i>
                        </Button>
                      </Link>
                      <Button className={classes.buttonDelete} onClick={e => (deleteAliado(row.id))}>
                        <i className="fal fa-trash-alt fz-24"></i>
                      </Button>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className="wrapper-pagination mb-64">
        <div className="pagination-button  color-primary" onClick={prevPage}>
          <div className="button-finger">
            <i className="fal fa-chevron-left fz-24"></i>
          </div>
        </div>
        <div className="pagination-numeric ">
          <span>1 de 10</span>
        </div>
        <div className="pagination-button color-primary" onClick={nextPage}>
          <div className="button-finger">
            <i className="fal fa-chevron-right  fz-24"></i>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export { Aliados };
