import React from "react";
import "./Business.css";

// Components
import List from "./components/table/List";

export default function Business() {
  return (
    <>
      <List></List>
    </>
  );
}
