import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "./Navbarcli.css";

//Recursos
import logo from '../../../assets/images/logohoom1.png';

class Navbarcli extends Component {

  goBack = () => {
    window.history.back();
    
  }
  goHome = () => {
    console.log(window.history)
  }


  render() {
    
    return (
      <div className="nav-fixed text-blue-ligth justify-content-center bs-1 h-56">
        <div className="d-flex justify-content-center align-items-center position-relative h-100 bg-white-0">
          <i className="fal fa-chevron-left icon-left cursor-pointer" onClick={this.goBack}></i>
          <a href='/cliente/home'>
            <img onClick={this.goHome} src={logo} alt="logo" className="w-30-p d-block mx-auto" />
            </a>
        </div>
      </div>
    );
  }
}

export default Navbarcli;