import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./InformacionEmpresa.css";
import { Redirect } from "react-router-dom";
// Components
import Sede from "./../sedeEmpresa/Sede";
import UsuarioEmpresa from "./../usuarioEmpresa/UsuariosEmpresa";
import SolicitudesEmpresa from "./../../../solicitudesEmpresa/SolicitudesEmpresa";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import { TextField } from "@material-ui/core";

import { API, graphqlOperation, Storage, Auth } from "aws-amplify";
import { getEmpresa } from "./../../../../../graphql/queries";
import { updateEmpresa, updateSedes } from "./../../../../../graphql/mutations";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: "blur(4px)",
  },
  paper: {
    backgroundColor: "white",
    borderRadius: 12,
    width: 632,
    padding: 36,
  },

  TextFieldMultinea: {
    width: "100%",
    border: "1px solid #63BAB3",
    borderRadius: 11,
    "& > div::before": {
      display: "none",
    },
    "& > div::after": {
      display: "none",
    },
    "& > label.MuiInputLabel-shrink": {
      transform: "translate(0, 16px) scale(0.75)",
    },
  },
}));

export default function InformacionEmpresa() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [sedes, setSedes] = useState([]);
  const [logo, setLogo] = useState({});
  const [nuevoLogo, setNuevoLogo] = useState({});
  const [redirigir, setRedirigir] = useState(false);
  const [empresaState, setEmpresaState] = useState({
    razon: "",
    nit: "",
    representante: "",
    correo: "",
    telefono: "",
    direccion: "",
    ciudadPrincipal: "",
    id: "",
  });
  const [empresaStateEdit, setEmpresaStateEdit] = useState({
    razon: "",
    nit: "",
    representante: "",
    correo: "",
    telefono: "",
    direccion: "",
    ciudadPrincipal: "",
    id: "",
  });

  useEffect(() => {
    validateSession()
    getEmpresaInitial();
  }, []);

  const getEmpresaInitial = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const idObject = Object.fromEntries(urlSearchParams.entries());
    const nLength = Object.keys(idObject).length;

    if (nLength > 0) {
      let idEmpresa = idObject.id;
      const empresa = await API.graphql(
        graphqlOperation(getEmpresa, { id: idEmpresa })
      );
      setEmpresaState(empresa.data.getEmpresa);
      setEmpresaStateEdit(empresa.data.getEmpresa);
      setLogo(await Storage.get(empresa.data.getEmpresa.logo));
      let sedeFiltradas = empresa.data.getEmpresa.sedes.items.filter((a) => {
        if (a.estado !== false && a.principal !== true) {
          return a;
        }
      });
      setSedes(sedeFiltradas);
      console.log(empresa);
    } else {
      console.log("No tiene un ID Valido");
    }
  };

  function setInputEmpresa(key, value) {
    setEmpresaStateEdit({ ...empresaStateEdit, [key]: value });
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setNuevoLogo({})
    setOpen(false);
  };

  const redirecToScroll = (url) => {
    window.location.href = url;
  };

  const actualizarEmpresa = async () => {

    //Se busca Sede Principal
    let sedePrincipalID = empresaStateEdit.sedes.items.find((a) => {
      if (a.estado === true && a.principal === true) return a.id;
    });
    let input;
    //Validar si Cambio la imagen para subirla y actualizarla
    if(nuevoLogo // 👈 null and undefined check
      && Object.keys(nuevoLogo).length === 0
      && Object.getPrototypeOf(nuevoLogo) === Object.prototype){
        input = {
          id: empresaStateEdit.id,
          razon: empresaStateEdit.razon.toUpperCase(),
          nit: empresaStateEdit.nit,
          representante: empresaStateEdit.representante.toUpperCase(),
          correo: empresaStateEdit.correo,
          telefono: empresaStateEdit.telefono,
          direccion: empresaStateEdit.direccion,
          ciudadPrincipal: empresaStateEdit.ciudadPrincipal,
        }
      }else{
        let fileUploaded = "";
        if (nuevoLogo != undefined) {
          await Storage.put(nuevoLogo.name, nuevoLogo, {
            /*progressCallback(progress) {
              setPorUpload((progress.loaded * 100)/ progress.total)
            }*/
          })
            .then((result) => {
              fileUploaded = result.key;
              console.log(result);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        input = {
          id: empresaStateEdit.id,
          razon: empresaStateEdit.razon.toUpperCase(),
          nit: empresaStateEdit.nit,
          representante: empresaStateEdit.representante.toUpperCase(),
          correo: empresaStateEdit.correo,
          telefono: empresaStateEdit.telefono,
          direccion: empresaStateEdit.direccion,
          ciudadPrincipal: empresaStateEdit.ciudadPrincipal,
          logo:fileUploaded
        }
      }
    //se actualiza Sede Principal
    let sendDataPrincipal = {
      query: updateSedes,
      variables: {
        input : {
          id: sedePrincipalID.id,
          telefono: empresaStateEdit.telefono,
          direccion: empresaStateEdit.direccion,
          ciudad: empresaStateEdit.ciudadPrincipal,
        },
      },
    };
    await API.graphql(sendDataPrincipal)
      .then((result) => {
        console.log("Se actualiza Sede Principal", result);
      })
      .catch((err) => {
        console.log("error sede", err);
        alert("Ocurrio un error");
      });

    //Se actualiza Cabecera de Empresas
    let sendData = {
      query: updateEmpresa,
      variables: {
        input,
      },
    };

    await API.graphql(sendData)
      .then((result) => {
        console.log("Se actualiza Cabecera correctamente", result);
        getEmpresaInitial();
        setOpen(false);
      })
      .catch((err) => {
        console.log("Error formulario ", err);
        alert("Ocurrio un error");
      });
  };

  async function setInputFile(files) {
    const file = files[0];
    let url = URL.createObjectURL(file);
    if (/\.(jpg|png|gif)$/i.test(file.name)) {
       setNuevoLogo(file);
      // setImage(url);
    }
  }
  function validateSession() {
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
    .then((user) => {
      console.log("Usuario Conectado");
    })
    .catch((err) => setRedirigir(true));
  }

  if (redirigir) {
    return <Redirect to="/admin/aliados" />;
  }

  return (
    <>
      <div className="w-100 pb-5">
        <div className="wrapper-general pt-4 position-relative mb-28">
          <div
            className="d-flex jusitfy-content-between align-items-center "
            id="INFORMACIÓN-DE-LA-EMPRESA"
          >
            <Link
              to="/admin/empresas"
              className="btn-finger fz-24 text-blue-ligth position-absolute"
            >
              <i className="fal fa-chevron-left"></i>
            </Link>
            <h2 className="text-dark-1 fz-28 fw-700 title-business text-center w-100">
              Información de empresa
            </h2>
          </div>
        </div>
        <div className="wrapper-general">
          <div className="w-100 border-dashed-blue">
            <div className="row mx-auto py-20">
              <div className="col-md-3 px-0 d-flex align-items-center justify-content-center">
                <img
                  src={logo}
                  alt="logo"
                  width="245px"
                  className="logoBrand"
                />
              </div>
              <div className="col-md-9 border-left-blue  d-flex align-items-center">
                <div className="row mx-auto py-24">
                  <div className="col-xl-3 col-lg-6 col-md-12 px-2 d-flex flex-column ">
                    <p className="text-truncate text-blue fz-12 mb-0">
                      Razón Social
                    </p>
                    <p className="text-truncate text-dark-1 fz-14">
                      {empresaState.razon}
                    </p>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-12 px-2 d-flex flex-column ">
                    <p className="text-truncate text-blue fz-12 mb-0">NIT</p>
                    <p className="text-truncate text-dark-1 fz-14">
                      {empresaState.nit}
                    </p>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-12 px-2 d-flex flex-column ">
                    <p className="text-truncate text-blue fz-12 mb-0">
                      Representante Legal
                    </p>
                    <p className="text-truncate text-dark-1 fz-14">
                      {empresaState.representante}
                    </p>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-12 px-2 d-flex flex-column ">
                    <p className="text-truncate text-blue fz-12 mb-0">
                      Ciudad Sede Principal
                    </p>
                    <p className="text-truncate text-dark-1 fz-14">
                      {empresaState.ciudadPrincipal}
                    </p>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-12 px-2 d-flex flex-column ">
                    <p className="text-truncate text-blue fz-12 mb-0">
                      Dirección sede principal
                    </p>
                    <p className="text-truncate text-dark-1 fz-14">
                      {empresaState.direccion}
                    </p>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-12 px-2 d-flex flex-column ">
                    <p className="text-truncate text-blue fz-12 mb-0">
                      Teléfono sede principal
                    </p>
                    <p className="text-truncate text-dark-1 fz-14">
                      {empresaState.telefono}
                    </p>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-12 px-2 d-flex flex-column ">
                    <p className="text-truncate text-blue fz-12 mb-0">
                      Correo de contacto
                    </p>
                    <p className="text-truncate text-dark-1 fz-14">
                      {empresaState.correo}
                    </p>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-12 px-2 d-flex flex-column  ">
                    <button
                      onClick={handleOpen}
                      className="btn-custom bg-blue-ligth text-white box-shadow-1 fz-14 fw-700 position-relative "
                    >
                      EDITAR INFORMACIÓN
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Sede sedes={sedes} setSedes={setSedes} creacionAutomatica={true} />

        <UsuarioEmpresa />

        <div className="w-100 pb-64 d-block">
          {/* <SolicitudesEmpresa /> */}
        </div>
      </div>

      <div className="navegacion-flotante">
        <div className="wrapper-general">
          <div className="row mx-auto">
            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12 mb-2">
              <Link to="/admin/empresas" className="w-100">
                <button className="btn-custom bg-blue-dark text-white box-shadow-1 fz-14 fw-700">
                  ATRÁS
                </button>
              </Link>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2 offset-xl-1">
              <button
                className="btn-custom bg-blue-ligth text-white box-shadow-1 fz-14 fw-700"
                onClick={() => {
                  redirecToScroll("#INFORMACIÓN-DE-LA-EMPRESA");
                }}
              >
                INFORMACIÓN DE LA EMPRESA
              </button>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2">
              <button
                className="btn-custom bg-blue-ligth text-white box-shadow-1 fz-14 fw-700"
                onClick={() => {
                  redirecToScroll("#MÉTRICAS-DE-LA-EMPRESA");
                }}
              >
                MÉTRICAS DE LA EMPRESA
              </button>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2">
              <button
                className="btn-custom bg-yellow text-white box-shadow-1 fz-14 fw-700"
                onClick={() => {
                  redirecToScroll("#SOLICITUDES-DE-LA-EMPRESA");
                }}
              >
                SOLICITUDES DE LA EMPRESA
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 className="fz-28 fw-700 text-dark-1 text-center mb-20">
              Editar Empresa
            </h2>
            <div className="w-100 bg-gray-3 d-flex justify-content-center align-items-center p-2 mb-2 ">
              <img src={logo} width="250px" alt="logo" />
            </div>
            <input
              accept="image/*"
              className="d-none"
              id="contained-button-file"
              multiple
              type="file"
              onChange={(event) => {
                setInputFile(event.target.files);
              }}
            />
            <label
              htmlFor="contained-button-file"
              className="text-center mx-auto d-block text-blue-ligth fz-12 mb-20 "
            >
              <u>Cambiar imagen de logotipo</u>
            </label>
            <div className="row mx-auto">
              <div className="col-md-6 mb-16">
                <TextField
                  id="razon"
                  label="Razón social"
                  className="text-fiel-custom-blue"
                  onChange={(event) =>
                    setInputEmpresa("razon", event.target.value)
                  }
                  value={empresaStateEdit.razon}
                />
              </div>
              <div className="col-md-6 mb-16">
                <TextField
                  id="nit"
                  label="NIT"
                  className="text-fiel-custom-blue"
                  onChange={(event) =>
                    setInputEmpresa("nit", event.target.value)
                  }
                  value={empresaStateEdit.nit}
                />
              </div>
              <div className="col-md-6 mb-16">
                <TextField
                  id="representante"
                  label="Representante legal"
                  className="text-fiel-custom-blue"
                  onChange={(event) =>
                    setInputEmpresa("representante", event.target.value)
                  }
                  value={empresaStateEdit.representante}
                />
              </div>
              <div className="col-md-6 mb-16">
                <TextField
                  id="ciudad"
                  label="Ciudad sede principal"
                  className="text-fiel-custom-blue"
                  onChange={(event) =>
                    setInputEmpresa("ciudadPrincipal", event.target.value)
                  }
                  value={empresaStateEdit.ciudadPrincipal}
                />
              </div>
              <div className="col-md-6 mb-16">
                <TextField
                  id="direccion"
                  label="Dirección sede Principal"
                  className="text-fiel-custom-blue"
                  onChange={(event) =>
                    setInputEmpresa("direccion", event.target.value)
                  }
                  value={empresaStateEdit.direccion}
                />
              </div>
              <div className="col-md-6 mb-16">
                <TextField
                  id="telefono"
                  label="Teléfono sede principal"
                  className="text-fiel-custom-blue"
                  onChange={(event) =>
                    setInputEmpresa("telefono", event.target.value)
                  }
                  value={empresaStateEdit.telefono}
                />
              </div>
              <div className="col-md-12 mb-36">
                <TextField
                  id="correo"
                  label="Correo de contacto"
                  className="text-fiel-custom-blue"
                  onChange={(event) =>
                    setInputEmpresa("correo", event.target.value)
                  }
                  value={empresaStateEdit.correo}
                />
              </div>
              <div className="col-md-4 offset-lg-2">
                <button
                  onClick={handleClose}
                  className="btn-custom bg-blue-dark text-white fz-16 fw-700 position-relative "
                >
                  <div className="btn-finger text-white fz-24 icon-button-start">
                    <i className="fal fa-times"></i>
                  </div>
                  CANCELAR
                </button>
              </div>
              <div className="col-md-4">
                <button
                  className="btn-custom bg-yellow text-white fz-16 fw-700 position-relative"
                  onClick={actualizarEmpresa}
                >
                  <div className="btn-finger text-white fz-24 icon-button-start">
                    <i className="fal fa-save"></i>
                  </div>
                  GUARDAR
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
