import { useState, useEffect, Fragment } from "react";
import "./SubirImages.css";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { API, graphqlOperation, Storage, Auth } from "aws-amplify";
import { createArchivosSolicitud, updateSolicitudes } from "./../../../../../../graphql/mutations";
import { Redirect } from "react-router-dom";
import moment from 'moment'



const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },

    "& > *": {
      margin: theme.spacing(1),
    },
  },

  input: {
    display: "none",
  },



}));

function SubirImagenes({ imagenes, setImagenes, setActualiza, actualiza, estadoGlobal }) {
  const classes = useStyles();
  let [siderbar, saveChangeSiderbar] = useState(false);
  let [redirigir, setRedirigir] = useState(false);

  const btnAccion = (estado) => {
    let response = null;
    let txtbutton = ""
    if (estado < 2) {
      if (estado == 0){
        txtbutton = "COMENZAR SOLICITUD"
      }else{
        txtbutton = "FINALIZAR SOLICITUD"
      }
      response = (<button className="btn-custom bg-yellow text-white fz-16 fw-700 " onClick={comenzarSolicitud}>
        {txtbutton}</button>)
    } else {
      response = null;
    }
    return response
  }

  const comenzarSolicitud = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const idObject = Object.fromEntries(urlSearchParams.entries());
    const nLength = Object.keys(idObject).length;
    if (nLength > 0) {
      let idSolicitud = idObject.id;
      //COnvertir a INT
      var estadoID = parseInt(estadoGlobal);
      //Sumar un estado
      estadoID = estadoID + 1
      if (estadoID === 3) return;
      //Condicion
      let jsonData = {
        id: idSolicitud,
        estado: estadoID.toString(),
      }
      if (estadoID === 2) {
        jsonData = {
          id: idSolicitud,
          estado: estadoID.toString(),
          fechaEjecucion: moment().format()
        }
      }
      let sendData = {
        query: updateSolicitudes,
        variables: {
          input: jsonData,
        },
      };

      await API.graphql(sendData)
        .then((result) => {
          console.log("resul", result);
          setRedirigir(true)
        })
        .catch((err) => {
          console.log("error", err);
          alert("Ocurrio un error");
        });

    }
  }

  const handleChangeFi = async ({ target }) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const idObject = Object.fromEntries(urlSearchParams.entries())
    const nLength = Object.keys(idObject).length;
    let idSolicitud = idObject.id;
    const file = target.files[0];
    console.log('fie----->', file)
    if (file !== undefined) {
      const url = URL.createObjectURL(file);
      let img = {
        file,
        url,
        name: file.name,
      };
      // setImages([...images, img]);
      let numB = Math.floor(Math.random() * 100000)
      let nombreFile;
      await Storage.put(numB + img.name, img.file, {
      })
        .then((result) => {
          console.log('se sube', result.key);
          nombreFile = result.key
        })
        .catch((err) => {
          console.log(err);
          // setCargando(false);
        });
      //Se registran los archivos
      let sendDataSolicitudArchivos = {
        input: {
          solicitudID: idSolicitud,
          nombre: nombreFile,
          imagen: true
        },
      };
      await API.graphql(graphqlOperation(createArchivosSolicitud, sendDataSolicitudArchivos))
        .then((result) => {
          //console.log("Se crea SolicitudArchivos", result);
          //getImagenes()
          setActualiza(!actualiza)
        })
        .catch((err) => {
          console.log("Error de la operacion de creacion ", err);
          // setCargando(false);
        });
    }

  }
  if (redirigir) {
    return <Redirect to="/admin/solicitudes" />;
  }

  return (
    <Fragment>
      <div className="position-relative" style={{ zIndex: "5" }}>
        <div className={`siderBar-const-image ${siderbar ? "active" : ""}`}>
          <div className="overflow-auto siderBar-const-image--container">
            <div className="siderBar-const-image--list">
              {
                imagenes.map((a, b) => (
                  <div className="siderBar-const-image--list--item" key={b}>
                    <img src={a} alt="Solicitud" width="100%" height="100%" />
                  </div>
                ))
              }
            </div>
          </div>
          <div className="siderBar-const-footer bg-white">
            <div className="box-shadow-2 w-100 d-flex justify-content-center align-items-center py-3 position-relative">
              <div className="col-lg-6 col-md-10">
                <input
                  accept="image/*"
                  className={classes.input}
                  id="imge-button-file"
                  multiple
                  type="file"
                  onChange={handleChangeFi}
                />

                <label htmlFor="imge-button-file">


                  <IconButton
                    component="span"
                    className={classes.margin}
                    size="small"
                  >
                    <div
                      className="btn-custom bg-blue-ligth text-white box-shadow-1 fz-14 fw-700 position-relative mb-2"
                      style={{ maxWidth: 200 }}
                    >
                      SUBIR IMAGEN
                    </div>
                  </IconButton>

                </label>
              </div>
            </div>
            <div className="box-shadow-2 w-100 d-flex justify-content-center align-items-center py-3 position-relative">
              <div className="col-lg-8 col-md-10">
                {/* <button className="btn-custom bg-yellow text-white fz-16 fw-700 " onClick={comenzarSolicitud}>
                  COMENZAR SOLICITUD
                </button> */}
                {btnAccion(estadoGlobal)}
              </div>
            </div>
          </div>
          <div
            className="button-float-siderbar"
            onClick={() => {
              saveChangeSiderbar(!siderbar);
            }}
          >
            <i className="far fa-chevron-left"></i>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export { SubirImagenes };
