import React, { useEffect, Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Backdrop from "@material-ui/core/Backdrop";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { listAliados, listAliadosSolicituds } from "./../../../../../../graphql/queries";
import { createAliadosSolicitud, updateAliadosSolicitud } from "./../../../../../../graphql/mutations";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: "blur(4px)",
  },
  paper: {
    backgroundColor: "white",
    borderRadius: 12,
    width: 472,
    paddingTop: 32,
    paddingRight: 16,
    paddingLeft: 16,
    paddingBottom: 32,
  },
}));

const form = {
  ciudad: "",
  razon: "",
  id: "", 
  estado: true
};

let initialSolicitud = {
  aliados: ''
}

function AliadosAsignados({solicitudData}) {
  
  const [aliados, setAliados] = useState([]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState(form); 
  const [listAliadosData, setListAliadosData] = useState([]);
  const [solicitud, setsolicitud] = useState(initialSolicitud)
 
  useEffect(() =>{    
    getAliadosData()
     getAliadosSeleccionados()
  },[open])

  const getAliadosData = async () => {
    const allTodos = await (API.graphql({ query: listAliados }));
    let listAutocomplete = [];
    allTodos.data.listAliados.items.map(a => {
      listAutocomplete.push({
        id:a.id, razon: a.razon
      })
    });
    setListAliadosData(listAutocomplete)
  }

  const getAliadosSeleccionados = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const idObject = Object.fromEntries(urlSearchParams.entries());
    let idSolicitud = idObject.id;

    let filtrar = {};
    filtrar.solicitudID = {};
    filtrar.solicitudID.contains = idSolicitud;
    filtrar.estado = {};
    filtrar.estado.eq = true;
    const allTodos = await API.graphql({ 
      query: listAliadosSolicituds,
      variables: {
      filter: filtrar
    }}).then(a => {
      console.log('seleccionados', a)
      setAliados(a.data.listAliadosSolicituds.items)
    })
  }

  async function addAliado () {
    let usuario;
    let aliadoCreado;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const idObject = Object.fromEntries(urlSearchParams.entries());
    let idSolicitud = idObject.id;
    await Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
    .then((user) => {
      usuario = user;
    })
    .catch((err) => alert("Deberia de Iniciar Sesion"));
    
    let sendData = {
      input: {
        solicitudID: idSolicitud,
        nombre: formState.razon,
        aliadoID: formState.id,
        estado: true
      }
    };
    
    await API.graphql(graphqlOperation(createAliadosSolicitud, sendData))
      .then((result) => {
        console.log('Aliado Creado', result)
      })
      .catch((err) => {
        console.log("Error de la operacion de creacion ", err);
      });
      setOpen(false);
  }

  function setSelect(value) {
    setFormState({ ...formState, id: value.id, razon: value.razon});
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function deleteAliado(id) {
    console.log(id)

       let usuario;
       await Auth.currentAuthenticatedUser({
         bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
       })
       .then((user) => {
         usuario = user;
       })
       .catch((err) => alert("Deberia de Iniciar Sesion"));

       let sendData = {
         query: updateAliadosSolicitud,
         variables: {
           input: {
             id: id,
             estado: false,
           },
         },
       };
       await API.graphql(sendData)
         .then((result) => {
           console.log("resul", result);
           getAliadosSeleccionados()
         })
         .catch((err) => {
           console.log("error", err);
           alert("Ocurrio un error");
         });

  }

  return (
    <Fragment>
      <p className="text-blue-ligth fw-700 mb-3">Aliados asignados</p>
      <div className="list-content mb-4">
      
      {!aliados.length ? (
          <h2 className="text-gray-5 text-center fw-700 fz-24 mb-0">
            No hay aliados para mostrar
          </h2>
        ) : null}
        {aliados.map((a, b) => (
        <div className="list-content-item" key={b}>
          <div className="d-flex align-items-center">
            <div className="btn-finger fz-24 text-dark-1 me-1">
              <i className="fal fa-user-hard-hat"></i>
            </div>
            <p className="text-dark-1 fz-14 mb-0 d-inline-block text-truncate ">
              {a.aliado.razon}
            </p>
          </div>
          <div className="d-flex flex-column">
          <div className="btn-finger fz-24 text-red"
            onClick={(event) => deleteAliado(a.id)} >
            <i className="far fa-trash-alt"></i>
          </div>
          </div>
        </div>

        ))}

      </div>
      <div className="row mx-auto mb-48">
        <div className="d-flex">
          <button
            className="btn-custom bg-blue-ligth text-white box-shadow-1 fz-14 fw-700 position-relative "
            style={{ maxWidth: 188 }}
            onClick={handleOpen}
          >
            ASIGNAR ALIADO
          </button>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 className="fz-28 fw-700 text-dark-1 text-center mb-20">
              ¿Qué aliado quiere asignar?
            </h2>
            <div className="row mx-auto">
              <div className="col-md-12 mb-48">
                <Autocomplete
                  id="combo-box-demo"
                  options={listAliadosData}
                  getOptionLabel={(option) => option.razon}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Aliado"
                      className="text-fiel-custom-blue"
                    />
                  )} 
                  onChange={(event, newValue) => {
                    setSelect(newValue);
                  }} 
                />
              </div>
              <div className="col-md-6 ">
                <button
                  onClick={handleClose}
                  className="btn-custom bg-blue-dark text-white fz-16 fw-700 position-relative "
                >
                  <div className="btn-finger text-white fz-24 icon-button-start">
                    <i className="fal fa-times"></i>
                  </div>
                  CANCELAR
                </button>
              </div>
              <div className="col-md-6">
                <button
                  onClick={addAliado}
                  className="btn-custom bg-yellow text-white fz-16 fw-700 position-relative "
                >
                  ASIGNAR ALIADO
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </Fragment>
  );
}

export { AliadosAsignados };
