import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import generator from "generate-password";

import "./UsuariosEmpresa.css";
import Fade from "@material-ui/core/Fade";

import { TextField } from "@material-ui/core";

import { API, Auth, graphqlOperation } from "aws-amplify";
import { updateSedes, createSedes } from "./../../../../../graphql/mutations";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: "blur(4px)",
  },
  paper: {
    backgroundColor: "white",
    borderRadius: 12,
    width: 632,
    padding: 36,
  },
  TextFieldMultinea: {
    width: "100%",
    border: "1px solid #63BAB3",
    borderRadius: 11,
    "& > label": {
      color: "#63BAB3",
    },
    "& > div::before": {
      display: "none",
    },
    "& > div::after": {
      display: "none",
    },
    "& > label.MuiInputLabel-shrink": {
      transform: "translate(0, 16px) scale(0.75)",
    },
  },
}));

const initialUsuario = {
  usuario: "",
  correo: "",
  celular: "",
  informacion: "",
  pass:"",
  grupo: "usuarios",
};

export default function UsuariosEmpresa() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [usuario, setUsuario] = useState(initialUsuario);
  const [usuarios, setUsuarios] = useState([]);
  let nextToken;
  useEffect(() => {
    getListUsers();
    //getUser();
  }, [open]);

  function setInput(key, value) {
    setUsuario({ ...usuario, [key]: value });
  }

  const addUsuario = async () => {
    if (usuario.usuario.trim() === "" || usuario.correo.trim() === "") {
      alert("Los Campos son obligatorios!");
      return false;
    }
    const urlSearchParams = new URLSearchParams(window.location.search);
    const idObject = Object.fromEntries(urlSearchParams.entries());
    const nLength = Object.keys(idObject).length;
    let idEmpresa = idObject.id;

    var password = generator.generate({
      length: 10,
      numbers: true,
      symbols: true,
      exclude: '~^|{}[]//(<>-,"´`);',
    });
    //Loguearse
    console.log('La Contraseña es', password)
    try {
      await Auth.signUp({
        username: usuario.correo,
        password: usuario.pass,
        attributes: {
          email: usuario.correo,
          phone_number: "+57" + usuario.celular,
          "custom:empresa": idEmpresa,
        },
      })
        .then((result) => {
          //console.log("Usuario Creado", result);
          addToGroup();
          //getListUsers()
          
        })
        .catch((err) => {
          switch (err.code) {
            case "UsernameExistsException":
              alert("Ya el nombre de usuario existe");
              break;
          }
          console.log("error usuario", err);
        });
    } catch (error) {
      console.log("ocurrio un error", error);
    }
    //console.log(formState);
  };

  async function addToGroup() {
    let apiName = "AdminQueries";
    let path = "/addUserToGroup";
    let myInit = {
      body: {
        username: usuario.correo,
        groupname: "usuarios",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    await API.post(apiName, path, myInit)
    setOpen(false);
    return ;
  }

  const getListUsers = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const idObject = Object.fromEntries(urlSearchParams.entries());
    const nLength = Object.keys(idObject).length;
    let idEmpresa = idObject.id;

    const limit = 60;
    let apiName = "AdminQueries";
    let path = "/listUsersInGroup";
    let myInit = {
      queryStringParameters: {
        groupname: "usuarios",
        limit: limit,
        token: nextToken,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    const { NextToken, ...rest } = await API.get(apiName, path, myInit);
    nextToken = NextToken;

    let nuevoArray = rest.Users.filter((a) => {
      return a.Attributes.find((b) => {
        if (b.Name === "custom:empresa" && b.Value === idEmpresa && a.Enabled) {
          return true;
        }
      });
    });

    setUsuarios(nuevoArray);
  };

  const handleOpen = () => {
    // setFormState(initalForm);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteUser = async (username) => {
    console.log(username);
    let apiName = "AdminQueries";
    let path = "/disableUser";
    let myInit = {
      body: {
        username: username,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    let respDelete = await API.post(apiName, path, myInit);
    setOpen(false);
    getListUsers();
    //console.log(respDelete);
  };

  const getUser = async () => {
    let apiName = "AdminQueries";
    let path = "/getUser";
    let myInit = {
      queryStringParameters: {
        username: "kmendozal",
        groupname: "usuarios",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    let q = await API.get(apiName, path, myInit);
    return q;
  };

  return (
    <>
      <div className="w-100 bg-blue-ligth-2 mb-44">
        <div className="wrapper-general ">
          <h2 className="text-blue text-center fw-700 fz-20 py-12 mb-0">
            Usuarios de la empresa
          </h2>
        </div>
      </div>

      <div className="wrapper-general mb-24">
        <div className="list-content">
          {!usuarios.length ? (
            <h2 className="text-gray-5 text-center fw-700 fz-24 m-auto">
              No hay usuarios para mostrar
            </h2>
          ) : (
            ""
          )}
          {usuarios.map((a) => (
            <div className="list-content-item" key={a.Username}>
              <div className="d-flex flex-column">
                <div className="btn-finger fz-24 text-dark-1">
                  <i className="fal fa-building "></i>
                </div>
              </div>
              
              <div className="d-flex flex-column">
                <p className="text-blue-ligth fz-12 mb-1">Correo electrónico</p>
                <p className="text-dark-1 fz-14 mb-0 d-inline-block text-truncate address">
                  {a.Attributes.find((a) => a.Name === "email").Value}
                </p>
              </div>
              <div className="d-flex flex-column">
                <p className="text-blue-ligth fz-12 mb-1">Celular</p>
                <p className="text-dark-1 fz-14 mb-0 d-inline-block text-truncate phone">
                  {a.Attributes.find((a) => a.Name === "phone_number").Value}
                </p>
              </div>
              <div className="d-flex flex-column">
                <p className="text-blue-ligth fz-12 mb-1">
                  Información adicional
                </p>
                <p className="text-dark-1 fz-14 mb-0 d-inline-block text-truncate additionalInformation">
                  -
                </p>
              </div>
              <div className="d-flex flex-column">
                <div
                  className="btn-finger fz-24 text-red"
                  onClick={(event) => deleteUser(a.Username)}
                >
                  <i className="far fa-trash-alt"></i>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="wrapper-general mb-28">
        <div className="row mx-auto justify-content-xl-end justify-content-center">
          {/* <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 mb-2">
            <button className="btn-custom bg-blue-dark text-white fz-14 fw-700 box-shadow-1">
              SUBIR EXCEL
            </button>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 mb-2">
            <button className="btn-custom bg-blue text-white fz-14 fw-700 box-shadow-1">
              BAJAR PLANTILLA
            </button>
          </div> */}
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2">
            <button
              className="btn-custom bg-blue-ligth text-white fz-14 fw-700 box-shadow-1 "
              onClick={handleOpen}
            >
              AGREGAR USUARIO
            </button>
          </div>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 className="fz-28 fw-700 text-dark-1 text-center mb-20">
              Añadir Usuario
            </h2>
            <div className="row mx-auto">
              <div className="col-md-6 mb-16">
                <TextField
                  id="usuario"
                  label="Nombre del usuario"
                  className="text-fiel-custom-blue"
                  onChange={(event) => setInput("usuario", event.target.value)}
                  value={usuario.usuario}
                />
              </div>
              <div className="col-md-6 mb-16">
                <TextField
                  type="password"
                  id="pass"
                  label="Contraseña"
                  className="text-fiel-custom-blue"
                  onChange={(event) => setInput("pass", event.target.value)}
                  value={usuario.pass}
                />
              </div>
              <div className="col-md-6 mb-16">
                <TextField
                  id="correo"
                  label="Correo electrónico"
                  className="text-fiel-custom-blue"
                  onChange={(event) => setInput("correo", event.target.value)}
                  value={usuario.correo}
                />
              </div>
              <div className="col-md-6 mb-16">
                <TextField
                  id="celular"
                  label="Celular"
                  className="text-fiel-custom-blue"
                  onChange={(event) => setInput("celular", event.target.value)}
                  value={usuario.celular}
                />
              </div>
              <div className="col-md-12 mb-24">
                <TextField
                  id="razon"
                  label="Información adicional"
                  className={classes.TextFieldMultinea}
                  onChange={(event) =>
                    setInput("informacion", event.target.value)
                  }
                  value={usuario.informacion}
                  multiline
                  rows={4}
                />
              </div>
              <div className="col-md-4 offset-lg-2">
                <button
                  onClick={handleClose}
                  className="btn-custom bg-blue-dark text-white fz-16 fw-700 position-relative "
                >
                  <div className="btn-finger text-white fz-24 icon-button-start">
                    <i className="fal fa-times"></i>
                  </div>
                  CANCELAR
                </button>
              </div>
              <div className="col-md-4">
                <button
                  className="btn-custom bg-yellow text-white fz-16 fw-700 position-relative "
                  onClick={addUsuario}
                >
                  <div className="btn-finger text-white fz-24 icon-button-start">
                    <i className="fal fa-user"></i>
                  </div>
                  AÑADIR
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
