/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEmpresa = /* GraphQL */ `
  query GetEmpresa($id: ID!) {
    getEmpresa(id: $id) {
      id
      razon
      nit
      representante
      correo
      logo
      usuario
      estado
      ciudadPrincipal
      telefono
      direccion
      sedes {
        items {
          id
          ciudad
          direccion
          telefono
          informacion
          principal
          nombre
          usuario
          estado
          empresaID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEmpresas = /* GraphQL */ `
  query ListEmpresas(
    $filter: ModelEmpresaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmpresas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        razon
        nit
        representante
        correo
        logo
        usuario
        estado
        ciudadPrincipal
        telefono
        direccion
        sedes {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSedes = /* GraphQL */ `
  query GetSedes($id: ID!) {
    getSedes(id: $id) {
      id
      ciudad
      direccion
      telefono
      informacion
      principal
      nombre
      usuario
      estado
      empresaID
      empresa {
        id
        razon
        nit
        representante
        correo
        logo
        usuario
        estado
        ciudadPrincipal
        telefono
        direccion
        sedes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSedes = /* GraphQL */ `
  query ListSedes(
    $filter: ModelSedesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSedes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ciudad
        direccion
        telefono
        informacion
        principal
        nombre
        usuario
        estado
        empresaID
        empresa {
          id
          razon
          nit
          representante
          correo
          logo
          usuario
          estado
          ciudadPrincipal
          telefono
          direccion
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAliados = /* GraphQL */ `
  query GetAliados($id: ID!) {
    getAliados(id: $id) {
      id
      razon
      tipo_documento
      numero_documento
      telefono
      correo
      ciudad
      estado
      cobertura_nacional
      informacion_adicional
      aliadosCiudades {
        items {
          id
          ciudad
          aliadoID
          createdAt
          updatedAt
        }
        nextToken
      }
      aliadosSolicitudes {
        items {
          id
          nombre
          solicitudID
          aliadoID
          estado
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAliados = /* GraphQL */ `
  query ListAliados(
    $filter: ModelAliadosFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAliados(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        razon
        tipo_documento
        numero_documento
        telefono
        correo
        ciudad
        estado
        cobertura_nacional
        informacion_adicional
        aliadosCiudades {
          nextToken
        }
        aliadosSolicitudes {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCiudades = /* GraphQL */ `
  query GetCiudades($id: ID!) {
    getCiudades(id: $id) {
      id
      nombre
      departamento
      estado
      createdAt
      updatedAt
    }
  }
`;
export const listCiudades = /* GraphQL */ `
  query ListCiudades(
    $filter: ModelCiudadesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCiudades(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nombre
        departamento
        estado
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAliadosCiudades = /* GraphQL */ `
  query GetAliadosCiudades($id: ID!) {
    getAliadosCiudades(id: $id) {
      id
      ciudad
      aliadoID
      aliado {
        id
        razon
        tipo_documento
        numero_documento
        telefono
        correo
        ciudad
        estado
        cobertura_nacional
        informacion_adicional
        aliadosCiudades {
          nextToken
        }
        aliadosSolicitudes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAliadosCiudades = /* GraphQL */ `
  query ListAliadosCiudades(
    $filter: ModelAliadosCiudadesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAliadosCiudades(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ciudad
        aliadoID
        aliado {
          id
          razon
          tipo_documento
          numero_documento
          telefono
          correo
          ciudad
          estado
          cobertura_nacional
          informacion_adicional
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSolicitudes = /* GraphQL */ `
  query GetSolicitudes($id: ID!) {
    getSolicitudes(id: $id) {
      id
      queNecesitas
      fechaEjecucion
      descripcion
      ciudad_sede
      sede
      usuario
      estado
      empresaID
      empresa {
        id
        razon
        nit
        representante
        correo
        logo
        usuario
        estado
        ciudadPrincipal
        telefono
        direccion
        sedes {
          nextToken
        }
        createdAt
        updatedAt
      }
      aliadosSolicitudes {
        items {
          id
          nombre
          solicitudID
          aliadoID
          estado
          createdAt
          updatedAt
        }
        nextToken
      }
      archivosSolicitudes {
        items {
          id
          solicitudID
          nombre
          imagen
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSolicitudes = /* GraphQL */ `
  query ListSolicitudes(
    $filter: ModelSolicitudesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSolicitudes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        queNecesitas
        fechaEjecucion
        descripcion
        ciudad_sede
        sede
        usuario
        estado
        empresaID
        empresa {
          id
          razon
          nit
          representante
          correo
          logo
          usuario
          estado
          ciudadPrincipal
          telefono
          direccion
          createdAt
          updatedAt
        }
        aliadosSolicitudes {
          nextToken
        }
        archivosSolicitudes {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAliadosSolicitud = /* GraphQL */ `
  query GetAliadosSolicitud($id: ID!) {
    getAliadosSolicitud(id: $id) {
      id
      nombre
      solicitudID
      aliadoID
      estado
      solicitud {
        id
        nombre
        departamento
        estado
        createdAt
        updatedAt
      }
      aliado {
        id
        razon
        tipo_documento
        numero_documento
        telefono
        correo
        ciudad
        estado
        cobertura_nacional
        informacion_adicional
        aliadosCiudades {
          nextToken
        }
        aliadosSolicitudes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAliadosSolicituds = /* GraphQL */ `
  query ListAliadosSolicituds(
    $filter: ModelAliadosSolicitudFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAliadosSolicituds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nombre
        solicitudID
        aliadoID
        estado
        solicitud {
          id
          nombre
          departamento
          estado
          createdAt
          updatedAt
        }
        aliado {
          id
          razon
          tipo_documento
          numero_documento
          telefono
          correo
          ciudad
          estado
          cobertura_nacional
          informacion_adicional
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getArchivosSolicitud = /* GraphQL */ `
  query GetArchivosSolicitud($id: ID!) {
    getArchivosSolicitud(id: $id) {
      id
      solicitudID
      nombre
      imagen
      solicitud {
        id
        queNecesitas
        fechaEjecucion
        descripcion
        ciudad_sede
        sede
        usuario
        estado
        empresaID
        empresa {
          id
          razon
          nit
          representante
          correo
          logo
          usuario
          estado
          ciudadPrincipal
          telefono
          direccion
          createdAt
          updatedAt
        }
        aliadosSolicitudes {
          nextToken
        }
        archivosSolicitudes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listArchivosSolicituds = /* GraphQL */ `
  query ListArchivosSolicituds(
    $filter: ModelArchivosSolicitudFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArchivosSolicituds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        solicitudID
        nombre
        imagen
        solicitud {
          id
          queNecesitas
          fechaEjecucion
          descripcion
          ciudad_sede
          sede
          usuario
          estado
          empresaID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPreferencias = /* GraphQL */ `
  query GetPreferencias($id: ID!) {
    getPreferencias(id: $id) {
      id
      ciudad
      sede
      usuario
      createdAt
      updatedAt
    }
  }
`;
export const listPreferencias = /* GraphQL */ `
  query ListPreferencias(
    $filter: ModelPreferenciasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPreferencias(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ciudad
        sede
        usuario
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
