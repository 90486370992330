import { useState, useEffect, Fragment } from "react";
import { Link } from 'react-router-dom';
import "./Versolicitud.css";

//MATERIAL UI
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Zoom from "@material-ui/core/Zoom";

//AWS
import { API, graphqlOperation, Storage, Auth } from "aws-amplify";
import { getSolicitudes, getEmpresa } from "./../../../graphql/queries";



//Data de prueba Archivos
const listFiles = [
];

//Data de prueba Archivos
const listImage = [
];

const currencies = {
  "1":"Adecuación de espacios de oficinas",
  "2":"Solicitar mobiliario",
  "3":"Servicios de instalaciones",
  "4":"Servicios de Mantenimientos",
};

const Versolicitud = () => {
  const [images, setImages] = useState(listImage);
  const [files, setDocument] = useState(listFiles);
  const [solicitud, setSolicitud] = useState({});

  useEffect(() => {
    getSolicitud();
  }, []);

  const goBack = () => {
    window.history.back();
    
  }

  const getSolicitud = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const idObject = Object.fromEntries(urlSearchParams.entries());
    const nLength = Object.keys(idObject).length;
    if (nLength > 0) {
      let idSolicitud = idObject.id;
      const solicitudData = await API.graphql(
        graphqlOperation(getSolicitudes, { id: idSolicitud })
      );
      //console.log('Solicitud', solicitudData)
      setSolicitud(solicitudData.data.getSolicitudes)
      let arrayImage = [];
      let arrayFile = [];
      const resp = await Promise.all(        
        solicitudData.data.getSolicitudes.archivosSolicitudes.items.map(async a => {          
          const signedURL = await Storage.get(a.nombre); // get key from Storage.list
          if(a.imagen) {
            arrayImage.push(signedURL);
          }else{
            arrayFile.push(signedURL);
          }
        })        
      );
      setImages(arrayImage)
      setDocument(arrayFile)
      
    }else {
      console.log("No tiene un ID Valido");
    }
  }

  function downloadFile() {
    
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }));

  function ScrollTop(props) {
    const { children, window } = props;
    const classes = useStyles();

    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 100,
    });

    const handleClick = (event) => {
      const anchor = (event.target.ownerDocument || document).querySelector(
        "#back-to-top-anchor"
      );

      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    };

    return (
      <Zoom in={trigger}>
        <div onClick={handleClick} role="presentation" className={classes.root}>
          {children}
        </div>
      </Zoom>
    );
  }

  ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
  };

  return (
    <Fragment>
      <div className="container-home">
        <div className="d-flex justify-content-center flex-column">
            <h5 className="color-dark-1 fs-28 fw-700 letter-s-1-2 fs-24 mt-40 mb-12">
              
              {currencies[solicitud.queNecesitas] }
            </h5>
            
              <h6 className="color-green-1 fs-12 lh-24 mb-4-px">Descripción de la solicitud</h6>
              <p className="text-start letter-s-0 fs-16 color-dark-2 d-block mb-20">
                {solicitud.descripcion}
              </p>

              <div className="d-flex align-items-center mb-16">
                <i className="far fa-user-hard-hat color-green-1"></i>
                <span className="fs-14 fw-600 text-dark-1 lh-19 ma4">Aliados asignados</span>
              </div>

              {/* <h6 className="text-start letter-s-0 fs-16 color-dark-2 d-block mb-8">Contratista Baq S.A.S</h6>
              <h6 className="text-start letter-s-0 fs-16 color-dark-2 d-block mb-8">Construcción futuro Ltda. </h6>
              <h6 className="text-start letter-s-0 fs-16 color-dark-2 d-block mb-20">Pilares S.A.S</h6> */}
          </div>

          <div className="d-flex align-items-center mb-16">
            <i className="far fa-image color-green-1"></i>
            <span className="fs-14 fw-600 text-dark-1 lh-19 ma4">Imagenes</span>
          </div>
      </div>

      {/* Carousel */}
      <div className="slider-solicitud mb-12">
        <div className="slider-solicitud-overflow">
          <div className="slider-solicitud-list">
            {images.map((img, index) => (
              <div className="slider-solicitud-item" key={index}>
                <a href={img} target="_blank">
                <img src={img} alt={img} className="w-100 h-100"/>
                </a>                    
                
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container-home">
        <div className="d-flex align-items-center mb-24">
          <i className="far fa-paperclip color-green-1"></i>
          <span className="fs-14 fw-600 text-dark-1 lh-19 ma4">Documentos adjuntos</span>
        </div>
        <div>
          <div className="container mb-28">
            {files.map((file, index) => (
              <div className="list-content-item bs-1 br-4 mb-12" style={{minWidth:280}} key={index}>
                <div className="d-flex flex-column">
                  <span>Doc Adjunto {index + 1}</span>
                </div>
                <div className="d-flex flex-column">
                  <div className="btn-finger fz-24 text-blue-ligth">
                    <a href={file} target="_blank"><i className="far fa-arrow-down"></i></a>                    
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        
        <Link to="#">
        <button
          type="button"
          onClick={goBack}
          className="btn fw-bold bg-yellow-0 bs-2 br-30 color-white-1 fs-14 w-100-p mh-48 py-13 px-3 mb-32"
        >
          ATRAS
        </button>
        </Link>
      </div>
    </Fragment>
  );
};
export default Versolicitud;