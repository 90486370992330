import { Fragment , useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import MuiAlert from "@material-ui/lab/Alert";
import { Spinner } from "react-bootstrap";

import logo from '../../../assets/images/logohoom1.png';
import "./Logincli.css";

//MaterialUI
import { TextField, Snackbar } from "@material-ui/core";

//Modal
import Fade from "@material-ui/core/Fade";
import Modal from '@material-ui/core/Modal';

//Amplify
import { Auth } from "aws-amplify";

const initalForm = {  
  correo_cliente: "",
  pass_cliente: "",
};

const Logincli = (props) => {
  //Modal estados
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

 //Estilos de modal
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const useStyles = makeStyles((theme) => ({
    //Estilos modal
  }));

  const classes = useStyles(); 

  //Checkbox
  const [setChecked] = useState(true);
  
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  //Login estados
  const [formState, setFormState] = useState(initalForm);
  const [open, setOpen] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [msgError, setMsgError] = useState("");
  const handleClose = () => setOpen(false);

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }    

  //Validacion activar enviar clave
  function Activar_enviar_clave() {
    const expr = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if(formState.correo_cliente.trim() === ""){
      setMsgError("Debe llenar el campo de correo electronico");
      setOpen(true);
      return false;
    }

    if (!expr.test(formState.correo_cliente)) {
      setMsgError("No corresponde a un correo electronico valido");
      setOpen(true);
      return false;
    }

    //Enviar correo electronico 
    handleOpenModal(); 
  }

  //Validaciones de formulario login
  async function ingresar_cliente() {
    if (formState.correo_cliente.trim() === "" && formState.pass_cliente.trim() === "") {
      setMsgError("Los Campos son obligatorios!");
      setOpen(true);
      return false;
    }
    
    //const correo_cliente = 'kmendozal';
    //const pass_cliente = 'kevinm0829';
    setCargando(true);
    try {
      const user_cli = await Auth.signIn(formState.correo_cliente, formState.pass_cliente);
      let grupos = user_cli.signInUserSession.idToken.payload['cognito:groups'];
      if(grupos.includes('usuarios')){
        //console.log("autenticado", user_cli.attributes['custom:empresa']);
        //console.log("autenticado", user_cli.signInUserSession.idToken.payload['cognito:groups']);
        props.history.push("/cliente/home");
      }else{
        setMsgError("Credenciales incorrectas");
        setOpen(true);  
      }      
      setCargando(false);
    } catch (error) {
        setMsgError("Credenciales incorrectas");
        setOpen(true);
        setCargando(false);
        return false;
    }
  }
 
  return ( 
    <Fragment>
       <div className="wrapper-lazy">
        <div className="bg-white w-100-p h-100-vh box-shadow-3">
              <div className='bg-blue-1 w-100 contenedor-p d-flex'>
                <div className="bg-white-0 box-shadow w-100 h-103 d-flex justify-content-center align-items-center flex-column">
                  <img src={logo} alt='logo' className="logo"/>
                </div>
              </div>

              <div className='d-flex justify-content-center flex-column px-32'>
                  <p className='letter-s-0 fw-700 text-center fs-28 mt-44 mb-16'>Bienvenido</p>
                  <p className='text-center color-blue-dark-1 letter-s-0 mb-28'>Escribe tu nombre de usuario y contraseña para iniciar sesión</p>
                  <form  noValidate autoComplete="off">
                    <TextField 
                      id="email_cli" 
                      label="Correo electrónico" 
                      className="text-fiel-custom-blue mb-12" 
                      onChange={(event) => setInput("correo_cliente", event.target.value)}
                      value={formState.correo_cliente}
                    />
                      
                    <TextField 
                    id="pass_cli" 
                    type="password" 
                    className="text-fiel-custom-blue mb-28"
                    label="Contraseña" 
                    onChange={(event) => setInput("pass_cliente", event.target.value)}
                    value={formState.pass_cliente}
                    />

                    <div className="group-checkbox mb-24">
                      <input
                        type="checkbox"
                        id="cobertura_general"
                        className="group-checkbox-input"
                        name="checked"
                      />
                      <label
                        className="group-checkbox-label"
                        htmlFor="cobertura_general"
                      >
                        <i className="far fa-check"></i>
                      </label>
                      <span className="text-dark-1">Recordarme en este dispositivo</span>
                    </div>
                    
                    <a href="#" onClick={Activar_enviar_clave} className="color-green-1 text-center d-block mb-24"> No recuerdo mi contraseña</a>

                    <button type="button" onClick={ingresar_cliente} className="bg-yellow-0 fw-bold btn w-100-p fs-14  br-30 bs-2 py-13 px-3 color-white-1 mh-48">
                      {cargando ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        "INGRESAR"
                      )}
                      </button>
                  </form>

                  <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                      {msgError}
                    </Alert>
                </Snackbar>
              </div>
          </div> 
        </div>

        {/* Modal */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModal}
          onClose={handleCloseModal}
          closeAfterTransition
        >
          <Fade in={openModal}>
            <div className="modal2">
              <div className="">
                <div className="bg-white-0 w-100 h-103 d-flex justify-content-center align-items-center flex-column">
                  <img src={logo} alt='logo' className="logo"/>
                </div>
                <h5 className="text-center dark-2-color letter-s-1">Correo Enviado</h5>
                <p className="text-center text-dark-1">Se ha enviado un <b>link</b> a tu correo  <b>usuari@finsocial.co</b> con el que podrás asignar una nueva <b>contraseña</b> a tu cuenta.</p>
                <button type="button" className="bg-yellow-0 fw-bold btn w-100-p fs-14  br-30 bs-2 py-13 px-3 color-white-1 mh-48" onClick={handleCloseModal}>ENTENDIDO</button>
              </div>
            </div>
          </Fade>
        </Modal>
        
    </Fragment>
  );
}

export default Logincli;