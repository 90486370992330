import {
  BrowserRouter as Router,
  Switch,
  Route,
  IndexRoute,
  Redirect,
} from "react-router-dom";
import "./App.css";
import "./assets/fontawesome-pro-5.15.3-web/css/all.min.css";

// Components
import { Layout } from "./pages/admin/layout/Layout";
import Home from "./pages/admin/home/Home";
import Business from "./pages/admin/business/Business";
import SolicitudesEmpresa from "./pages/admin/solicitudesEmpresa/SolicitudesEmpresa";
import { VerSolicitud } from "./pages/admin/solicitudesEmpresa/verSolicitud/VerSolicitud";
import { Aliados } from "./pages/admin/aliados/Aliados";
import { CrearAliados } from "./pages/admin/aliados/components/crearAliados/CrearAliados";
import CreateBusiness from "./pages/admin/business/components/createBusiness/CreateBusiness";
import InformacionEmpresa from "./pages/admin/business/components/informacionEmpresa/InformacionEmpresa";
import LoginAdmin from "./pages/admin/login/Login";

// Components cli
import Layoutcli from "./pages/cli/layoutcli/Layoutcli";
import Homecli from "./pages/cli/homecli/Homecli";
import Logincli from "./pages/cli/login/Logincli";
import Preferences from "./pages/cli/preferences/Preferences";
import Solicitudcli from "./pages/cli/solicitudcli/Solicitudcli";
import Solicitudes from "./pages/cli/Missolicitudescli/Missolicitudescli";
import Versolicitud from "./pages/cli/versolicitud/Versolicitud";
import Asignarclave from "./pages/cli/asignarclave/Asignarclave";

//Amplify
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/admin/login" exact component={LoginAdmin} />
        <Route path="/cliente/login" exact component={Logincli} />
        <Route path="/cliente/asignarclave" exact component={Asignarclave} />
        <Route path="/" exact component={Logincli} />

        <Route path="/cliente">
          <Layoutcli>
            <Route path="/cliente/home" exact component={Homecli} />
            <Route path="/cliente/preferencias" exact component={Preferences} />
            <Route path="/cliente/solicitud" exact component={Solicitudcli} />
            <Route path="/cliente/solicitudes" exact component={Solicitudes} />
            <Route
              path="/cliente/ver-solicitud"
              exact
              component={Versolicitud}
            />
          </Layoutcli>
        </Route>

        <Route path="/admin">
          <Layout>
            <Route path="/admin" exact component={Home} />
            <Route path="/admin/empresas" exact component={Business} />
            <Route
              path="/admin/empresas/crear-empresa"
              exact
              component={CreateBusiness}
            />
            <Route
              path="/admin/empresas/informacion-de-empresa"
              exact
              component={InformacionEmpresa}
            />
            <Route
              path="/admin/solicitudes"
              exact
              component={SolicitudesEmpresa}
            />
            <Route
              path="/admin/solicitudes/ver-solicitud"
              exact
              component={VerSolicitud}
            />
            <Route path="/admin/aliados" exact component={Aliados} />
            <Route path="/admin/crear-aliados" exact component={CrearAliados} />
            <Route path="/admin/ver-aliados" exact component={CrearAliados} />
          </Layout>
        </Route>
        <Route path="/">
          <Redirect to="/cliente/login" />
        </Route>
        <Route path="*">
          <Redirect to="/cliente/login" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
