import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./TablaSolicitudes.css";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import moment from 'moment'

import { listSolicitudes } from './../../../../graphql/queries';
import { API } from 'aws-amplify'
import { updateSolicitudes } from "../../../../graphql/mutations";

const columns = [
  {
    id: "id",
    label: "ID Solicitud",
    minWidth: 170,
  },
  { id: "razon", label: "Razón social", minWidth: 100 },
  {
    id: "fechaSolicitud",
    label: "Fecha de solicitud",
    minWidth: 170,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "solicitante",
    label: "Solicitante",
    minWidth: 170,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "phone",
    label: "Celular",
    minWidth: 170,
    format: (value) => value.toFixed(2),
  },
  {
    id: "city",
    label: "Ciudad",
    minWidth: 170,
    format: (value) => value.toFixed(2),
  },
  {
    id: "sede",
    label: "Sede",
    minWidth: 170,
    format: (value) => value.toFixed(2),
  },
  {
    id: "address",
    label: "Dirección",
    minWidth: 170,
    format: (value) => value.toFixed(2),
  },
  {
    id: "actions",
    label: "",
    minWidth: 170,
  },
];

const rows = [
  createData(
    "00000000",
    "00-00-0000 00:00:00",
    "Marta María Matos",
    "333 333 3333",
    "Barranquilla",
    "Sede Norte Cra 51B",
    "Cll 00 # 00 - 00",
    ""
  )
];

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#FFFFFF",
    color: "#707070",
    fontWeight: "700",
    height: "56px",
    background: "#FFFFFF",
    fontSize: 16,
    border: 0,
  },
  body: {
    fontSize: 16,
    color: "#707070",
    border: 0,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F5FCFF",
    },
  },
}))(TableRow);

function createData(
  idEmpresa,
  razonSocial,
  nit,
  city,
  phone,
  address,
  actions
) {
  return { idEmpresa, razonSocial, nit, city, phone, address, actions };
}

const useStyles = makeStyles({
  table: {
    width: "100%",
    boxShadow: "0",
  },
  tableHead: {
    padding: 72,
  },
  tableBody: {
    padding: 72,
  },
  tdId: {
    paddingLeft: 72,
  },
  container: {
    maxHeight: 'auto',
    minHeight: 700,
  },
  buttonEdit: {
    color: "#63bab3",
    fontSize: 24,
  },
  buttonDelete: {
    color: "#ff5858",
    fontSize: 24,
  },
  buttonLink: {
    textDecoration: "none",
  },
});

const currencies = {
  "1": "Adecuación de espacios de oficinas",
  "2": "Solicitar mobiliario",
  "3": "Servicios de instalaciones",
  "4": "Servicios de Mantenimientos",
};

export default function TablaSolicitudes({ estado }) {
  const [Solicitudes, setSolicitudes] = useState([])
  const [nextToken, setNextToken] = useState(undefined)
  const [nextNextToken, setNextNextToken] = useState()
  const [previousTokens, setPreviousTokens] = useState([])
  const [cargando, setCargando] = useState(true)
  const limit = null
  const [filtro, setFiltro] = useState(undefined)

  useEffect(() => {
    getList()
  }, [nextToken, filtro])

  async function getList() {
    setCargando(true)
    try {
      const listadoSolicitudesData = await API.graphql({
        query: listSolicitudes,
        variables: {
          limit,
          nextToken,
          filter: filtro
        }
      })
      let dataItem = listadoSolicitudesData.data.listSolicitudes.items.filter((a) => a.estado == estado);
      dataItem.sort(function(b,a){
        return a.createdAt.localeCompare(b.createdAt);
      })
      setSolicitudes(dataItem);
      setNextNextToken(listadoSolicitudesData.data.listSolicitudes.nextToken);
    } catch (err) {
      console.log(err)
    } finally {
      setCargando(false)
    }
  }

  async function nextPage() {
    setPreviousTokens((prev) => [...prev, nextToken])
    setNextToken(nextNextToken)
    setNextNextToken(null)
  }

  function prevPage() {
    setNextToken(previousTokens.pop())
    setPreviousTokens([...previousTokens])
    setNextNextToken(null)
  }

  async function deleteSolicitud(id) {
    setCargando(true)
    try {
      let sendData = {
        query: updateSolicitudes,
        variables: {
          input: {
            id: id,
            estado: 3
          },
        },
      };
      await API.graphql(sendData)
        .then((result) => {
          getList();
        })
        .catch((err) => {
          alert("Ocurrio un error");
        });
    } catch (err) {
      console.log(err)
    } finally {
      setCargando(false)
    }
  }



  const classes = useStyles();

  const [business, setBusiness] = useState("");

  function btneliminar(id, estado) {
    let response = null;
    if (estado == 0) {
      response = (<Button
        className={classes.buttonDelete}
        onClick={(e) => {
          deleteSolicitud(id);
        }}
      >
        <i className="fal fa-trash-alt fz-24"></i>
      </Button>)
    } else {
      response = null;
    }
    return response
  }
  return (
    <>
      <div className="w-100 px-0 position-relative list-business">
        <TableContainer component={Paper} className={classes.container}>
          <Table
            className={classes.table}
            stickyHeader
            aria-label="sticky table"
            id="tabla_solicitudes"
          >
            <TableHead className={classes.tableHead}>
              <TableRow>
                <StyledTableCell className={classes.tdId}>
                  ID Solicitud
                </StyledTableCell>
                <StyledTableCell>Fecha Solicitud</StyledTableCell>
                <StyledTableCell>Empresa</StyledTableCell>
                <StyledTableCell>Tipo</StyledTableCell>
                
                <StyledTableCell>Ciudad Sede</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {Solicitudes.map((solicitud) => (
                <StyledTableRow key={solicitud.idEmpresa}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    className={classes.tdId}
                  >
                    {solicitud.id.substring(solicitud.id.length - 3, solicitud.id.length)}
                  </StyledTableCell>
                  <StyledTableCell>{moment(solicitud.createdAt).format('YYYY/MM/DD hh:mm a')}</StyledTableCell>
                  <StyledTableCell>{solicitud.empresa.razon}</StyledTableCell>
                  <StyledTableCell>{currencies[solicitud.queNecesitas]}</StyledTableCell>
                  
                  <StyledTableCell>{solicitud.ciudad_sede}</StyledTableCell>
                  <StyledTableCell>
                    <div className="d-flex justify-content-center align-items-center">
                      <Link
                        className={classes.buttonLink}
                        to={`/admin/solicitudes/ver-solicitud/?id=${solicitud.id}`}
                      >
                        <Button className={classes.buttonEdit}>
                          <i class="fal fa-eye fz-24"></i>
                        </Button>
                      </Link>
                      {btneliminar(solicitud.id, solicitud.estado)}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {/* <div className="wrapper-pagination">
        <div className="pagination-button  color-primary" onClick={prevPage}>
          <div className="button-finger">
            <i className="fal fa-chevron-left fz-24"></i>
          </div>
        </div>
        <div className="pagination-numeric">
          <span>1 de 10</span>
        </div>
        <div className="pagination-button color-primary" onClick={nextPage}>
          <div className="button-finger">
            <i className="fal fa-chevron-right  fz-24"></i>
          </div>
        </div>
      </div> */}
    </>
  );
}
