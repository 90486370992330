import React, { useState } from "react";
import "./Login.css";
import logo from "../../../assets/images/logohoom1.png";

//MaterialUI
import { TextField, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import { Spinner } from "react-bootstrap";

//Amplify
import { Auth, API } from "aws-amplify";

const initalForm = {
  usuario: "",
  pass: "",
};
const LoginAdmin = (props) => {
  const [formState, setFormState] = useState(initalForm);
  const [open, setOpen] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [msgError, setMsgError] = useState("");

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  async function ingresar() {

    if (formState.usuario.trim() === "" || formState.pass.trim() === "") {
      setMsgError("Los Campos son obligatorios!");
      setOpen(true);
      return false;
    }
    setCargando(true);
    //Loguearse
    try {
      const user = await Auth.signIn(formState.usuario, formState.pass);
      //console.log("autenticado", user);
      let gruposPertenece = user.signInUserSession.idToken.payload["cognito:groups"];
      //console.log('Grupos', gruposPertenece)
      if(gruposPertenece.includes('admin')){
        props.history.push("/admin");
      }else{
        setMsgError("Credenciales incorrectas");
        setOpen(true);
      }
      
      setCargando(false);
    } catch (error) {
      //console.log('ocurrio un error', error)
      setMsgError("Credenciales incorrectas");
      setOpen(true);
      setCargando(false);
      return false;
    }
  }

  

  

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="background-login d-flex justify-content-center px-3">
      <div className="contenedor-login">
        <div className="contenedor-login-titulo d-flex justify-content-center px-3 mt-76">
          <img src={logo} alt="logo" />
        </div>
        <div className="mt-5 d-flex justify-content-center flex-column padding-login">
          <p className="titulo-login">¡Bienvenido!</p>
          <p className="subtitulo-login px-">
            Escribe tu nombre de <strong>usuario y contraseña</strong> para
            iniciar sesión
          </p>
          <form noValidate autoComplete="off">
            <TextField
              id="usuario"
              label="Correo"
              className="w-100"
              onChange={(event) => setInput("usuario", event.target.value)}
              value={formState.usuario}
            />
            <TextField
              id="pass"
              type="password"
              label="Contraseña"
              className="w-100 mt-3"
              onChange={(event) => setInput("pass", event.target.value)}
              value={formState.pass}
            />

            <button
              type="button"
              className="btn btn-login py-13 px-3 mt-36"
              onClick={ingresar}
            >
              {cargando ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "Ingresar"
              )}
            </button>
          </form>
          <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {msgError}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </div>
  );
};

export default LoginAdmin;
