import "date-fns";
import { useState, useEffect } from "react";
import { es } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { TextField } from "@material-ui/core";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

export default function FiltroSolicitudes({estado}) {
  // The first commit of Material-UI
  const [txtTipoSolicitud, setTxtTipoSolicitud] = useState([])
  const [selectedDate, setSelectedDate] = useState(new Date());
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {  
    let tipoSolicitud = ""
    if(estado==0){
      tipoSolicitud = "Solicitudes recibidas"
    }else if(estado==1){
      tipoSolicitud = "Solicitudes en proceso"
    }else{
      tipoSolicitud = "Solicitudes ejecutadas"
    }
    setTxtTipoSolicitud(tipoSolicitud)
  })

  return (
    <>
      <div className="wrapper-general mb-28">
        <div className="row">
          <div className="col-lg-4 col-md-12 d-flex align-items-center">
            <TextField
              id="search"
              label="Criterio de búsqueda"
              className="text-fiel-custom-blue"
            />
          </div>
          <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 d-flex align-items-center pb-2">
            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="yyyy/MM/dd"
                margin="normal"
                id="fechaInicial"
                label="Fecha Inicial"
                value={selectedDate}
                onChange={handleDateChange}
                className="text-fiel-custom-blue"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 d-flex align-items-center pb-2">
            <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="yyyy/MM/dd"
                margin="normal"
                id="fechaFinal"
                label="Fecha Final"
                value={selectedDate}
                onChange={handleDateChange}
                className="text-fiel-custom-blue"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 mb-2 d-flex align-items-center">
            <button className="btn-custom d-flex justify-content-center bg-yellow text-white box-shadow-1 fw-700 position-relative position-relative">
              <div className="btn-finger text-white fz-24 icon-button-start">
                <i className="fal fa-search"></i>
              </div>
              BUSCAR
            </button>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 mb-2 d-flex align-items-center">
            {/* <button className="btn-custom d-flex justify-content-center bg-blue-dark text-white box-shadow-1 fw-700 position-relative position-relative">
              EXPORTAR
              <div className="btn-finger text-white fz-24 icon-button-start">
                <i className="fal fa-file-excel"></i>
              </div>
            </button> */}
            <ReactHTMLTableToExcel
              id="xls-button"
              className="btn-custom d-flex justify-content-center bg-blue-dark text-white box-shadow-1 fw-700 position-relative position-relative"
              table="tabla_solicitudes"
              filename={txtTipoSolicitud}
              sheet={txtTipoSolicitud}
              buttonText='EXPORTAR'
            />
          </div>
        </div>
      </div>
    </>
  );
}
