import {Fragment , useState} from "react";
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import logo from '../../../assets/images/logohoom1.png';
import "./Asignarclave.css";

//MaterialUI
import { TextField } from '@material-ui/core';

const Asignarclave = () => {

    const useStyles = makeStyles((theme) => ({
      TextFieldCustom: {
        color: "green",
        width: "100%",
        margin: "15px 0 15px 0",
        "& > label": {
          color: "63BAB3#",
        },
        "& > div::before": {
          borderBottom: "1px solid #63BAB3",
        },
      },  
    }));
    
    const classes = useStyles();
    return (
      <Fragment>
      <div className="wrapper-lazy">
       <div className="bg-white w-100-p h-100-vh box-shadow-3">
             <div className='bg-yellow-3 w-100 py-60 d-flex justify-content-center box-shadow-3'>
                 <img src={logo} alt='logo' className="logo"/>
             </div>

             <div className='mt-5 d-flex justify-content-center flex-column px-32'>
                 <p className='letter-s-0 fw-700 text-center fs-28'>Asignación de contraseña</p>
                 <p className='text-center color-blue-dark-1 letter-s-0'>Para ingresa al portal es necesario crear una <b>contraseña</b> para el usuario <b>usuari@finsocial.co</b></p>
                 <form  noValidate autoComplete="off">
                   <TextField 
                     id="clave" 
                     type="password" 
                     label="Contraseña" 
                     className="text-fiel-custom-blue mb-5" />
                     
                   <TextField 
                   id="pass" 
                   type="password" 
                   className="text-fiel-custom-blue mb-5"
                   label="Confirmar contraseña" />
                   
                   <Link to="/cliente/login">
                     <button type="button" className="bg-yellow-0 fw-bold btn w-100-p fs-14  br-30 bs-2 py-13 px-3 mt-36 color-white-1 mh-48">ASIGNAR CONTRASEÑA</button>
                   </Link>
                 </form>
             </div>
         </div>           
       </div>
   </Fragment>
    );
}

export default Asignarclave;