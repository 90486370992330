/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEmpresa = /* GraphQL */ `
  mutation CreateEmpresa(
    $input: CreateEmpresaInput!
    $condition: ModelEmpresaConditionInput
  ) {
    createEmpresa(input: $input, condition: $condition) {
      id
      razon
      nit
      representante
      correo
      logo
      usuario
      estado
      ciudadPrincipal
      telefono
      direccion
      sedes {
        items {
          id
          ciudad
          direccion
          telefono
          informacion
          principal
          nombre
          usuario
          estado
          empresaID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEmpresa = /* GraphQL */ `
  mutation UpdateEmpresa(
    $input: UpdateEmpresaInput!
    $condition: ModelEmpresaConditionInput
  ) {
    updateEmpresa(input: $input, condition: $condition) {
      id
      razon
      nit
      representante
      correo
      logo
      usuario
      estado
      ciudadPrincipal
      telefono
      direccion
      sedes {
        items {
          id
          ciudad
          direccion
          telefono
          informacion
          principal
          nombre
          usuario
          estado
          empresaID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmpresa = /* GraphQL */ `
  mutation DeleteEmpresa(
    $input: DeleteEmpresaInput!
    $condition: ModelEmpresaConditionInput
  ) {
    deleteEmpresa(input: $input, condition: $condition) {
      id
      razon
      nit
      representante
      correo
      logo
      usuario
      estado
      ciudadPrincipal
      telefono
      direccion
      sedes {
        items {
          id
          ciudad
          direccion
          telefono
          informacion
          principal
          nombre
          usuario
          estado
          empresaID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSedes = /* GraphQL */ `
  mutation CreateSedes(
    $input: CreateSedesInput!
    $condition: ModelSedesConditionInput
  ) {
    createSedes(input: $input, condition: $condition) {
      id
      ciudad
      direccion
      telefono
      informacion
      principal
      nombre
      usuario
      estado
      empresaID
      empresa {
        id
        razon
        nit
        representante
        correo
        logo
        usuario
        estado
        ciudadPrincipal
        telefono
        direccion
        sedes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSedes = /* GraphQL */ `
  mutation UpdateSedes(
    $input: UpdateSedesInput!
    $condition: ModelSedesConditionInput
  ) {
    updateSedes(input: $input, condition: $condition) {
      id
      ciudad
      direccion
      telefono
      informacion
      principal
      nombre
      usuario
      estado
      empresaID
      empresa {
        id
        razon
        nit
        representante
        correo
        logo
        usuario
        estado
        ciudadPrincipal
        telefono
        direccion
        sedes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSedes = /* GraphQL */ `
  mutation DeleteSedes(
    $input: DeleteSedesInput!
    $condition: ModelSedesConditionInput
  ) {
    deleteSedes(input: $input, condition: $condition) {
      id
      ciudad
      direccion
      telefono
      informacion
      principal
      nombre
      usuario
      estado
      empresaID
      empresa {
        id
        razon
        nit
        representante
        correo
        logo
        usuario
        estado
        ciudadPrincipal
        telefono
        direccion
        sedes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAliados = /* GraphQL */ `
  mutation CreateAliados(
    $input: CreateAliadosInput!
    $condition: ModelAliadosConditionInput
  ) {
    createAliados(input: $input, condition: $condition) {
      id
      razon
      tipo_documento
      numero_documento
      telefono
      correo
      ciudad
      estado
      cobertura_nacional
      informacion_adicional
      aliadosCiudades {
        items {
          id
          ciudad
          aliadoID
          createdAt
          updatedAt
        }
        nextToken
      }
      aliadosSolicitudes {
        items {
          id
          nombre
          solicitudID
          aliadoID
          estado
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAliados = /* GraphQL */ `
  mutation UpdateAliados(
    $input: UpdateAliadosInput!
    $condition: ModelAliadosConditionInput
  ) {
    updateAliados(input: $input, condition: $condition) {
      id
      razon
      tipo_documento
      numero_documento
      telefono
      correo
      ciudad
      estado
      cobertura_nacional
      informacion_adicional
      aliadosCiudades {
        items {
          id
          ciudad
          aliadoID
          createdAt
          updatedAt
        }
        nextToken
      }
      aliadosSolicitudes {
        items {
          id
          nombre
          solicitudID
          aliadoID
          estado
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAliados = /* GraphQL */ `
  mutation DeleteAliados(
    $input: DeleteAliadosInput!
    $condition: ModelAliadosConditionInput
  ) {
    deleteAliados(input: $input, condition: $condition) {
      id
      razon
      tipo_documento
      numero_documento
      telefono
      correo
      ciudad
      estado
      cobertura_nacional
      informacion_adicional
      aliadosCiudades {
        items {
          id
          ciudad
          aliadoID
          createdAt
          updatedAt
        }
        nextToken
      }
      aliadosSolicitudes {
        items {
          id
          nombre
          solicitudID
          aliadoID
          estado
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCiudades = /* GraphQL */ `
  mutation CreateCiudades(
    $input: CreateCiudadesInput!
    $condition: ModelCiudadesConditionInput
  ) {
    createCiudades(input: $input, condition: $condition) {
      id
      nombre
      departamento
      estado
      createdAt
      updatedAt
    }
  }
`;
export const updateCiudades = /* GraphQL */ `
  mutation UpdateCiudades(
    $input: UpdateCiudadesInput!
    $condition: ModelCiudadesConditionInput
  ) {
    updateCiudades(input: $input, condition: $condition) {
      id
      nombre
      departamento
      estado
      createdAt
      updatedAt
    }
  }
`;
export const deleteCiudades = /* GraphQL */ `
  mutation DeleteCiudades(
    $input: DeleteCiudadesInput!
    $condition: ModelCiudadesConditionInput
  ) {
    deleteCiudades(input: $input, condition: $condition) {
      id
      nombre
      departamento
      estado
      createdAt
      updatedAt
    }
  }
`;
export const createAliadosCiudades = /* GraphQL */ `
  mutation CreateAliadosCiudades(
    $input: CreateAliadosCiudadesInput!
    $condition: ModelAliadosCiudadesConditionInput
  ) {
    createAliadosCiudades(input: $input, condition: $condition) {
      id
      ciudad
      aliadoID
      aliado {
        id
        razon
        tipo_documento
        numero_documento
        telefono
        correo
        ciudad
        estado
        cobertura_nacional
        informacion_adicional
        aliadosCiudades {
          nextToken
        }
        aliadosSolicitudes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAliadosCiudades = /* GraphQL */ `
  mutation UpdateAliadosCiudades(
    $input: UpdateAliadosCiudadesInput!
    $condition: ModelAliadosCiudadesConditionInput
  ) {
    updateAliadosCiudades(input: $input, condition: $condition) {
      id
      ciudad
      aliadoID
      aliado {
        id
        razon
        tipo_documento
        numero_documento
        telefono
        correo
        ciudad
        estado
        cobertura_nacional
        informacion_adicional
        aliadosCiudades {
          nextToken
        }
        aliadosSolicitudes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAliadosCiudades = /* GraphQL */ `
  mutation DeleteAliadosCiudades(
    $input: DeleteAliadosCiudadesInput!
    $condition: ModelAliadosCiudadesConditionInput
  ) {
    deleteAliadosCiudades(input: $input, condition: $condition) {
      id
      ciudad
      aliadoID
      aliado {
        id
        razon
        tipo_documento
        numero_documento
        telefono
        correo
        ciudad
        estado
        cobertura_nacional
        informacion_adicional
        aliadosCiudades {
          nextToken
        }
        aliadosSolicitudes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSolicitudes = /* GraphQL */ `
  mutation CreateSolicitudes(
    $input: CreateSolicitudesInput!
    $condition: ModelSolicitudesConditionInput
  ) {
    createSolicitudes(input: $input, condition: $condition) {
      id
      queNecesitas
      fechaEjecucion
      descripcion
      ciudad_sede
      sede
      usuario
      estado
      empresaID
      empresa {
        id
        razon
        nit
        representante
        correo
        logo
        usuario
        estado
        ciudadPrincipal
        telefono
        direccion
        sedes {
          nextToken
        }
        createdAt
        updatedAt
      }
      aliadosSolicitudes {
        items {
          id
          nombre
          solicitudID
          aliadoID
          estado
          createdAt
          updatedAt
        }
        nextToken
      }
      archivosSolicitudes {
        items {
          id
          solicitudID
          nombre
          imagen
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSolicitudes = /* GraphQL */ `
  mutation UpdateSolicitudes(
    $input: UpdateSolicitudesInput!
    $condition: ModelSolicitudesConditionInput
  ) {
    updateSolicitudes(input: $input, condition: $condition) {
      id
      queNecesitas
      fechaEjecucion
      descripcion
      ciudad_sede
      sede
      usuario
      estado
      empresaID
      empresa {
        id
        razon
        nit
        representante
        correo
        logo
        usuario
        estado
        ciudadPrincipal
        telefono
        direccion
        sedes {
          nextToken
        }
        createdAt
        updatedAt
      }
      aliadosSolicitudes {
        items {
          id
          nombre
          solicitudID
          aliadoID
          estado
          createdAt
          updatedAt
        }
        nextToken
      }
      archivosSolicitudes {
        items {
          id
          solicitudID
          nombre
          imagen
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSolicitudes = /* GraphQL */ `
  mutation DeleteSolicitudes(
    $input: DeleteSolicitudesInput!
    $condition: ModelSolicitudesConditionInput
  ) {
    deleteSolicitudes(input: $input, condition: $condition) {
      id
      queNecesitas
      fechaEjecucion
      descripcion
      ciudad_sede
      sede
      usuario
      estado
      empresaID
      empresa {
        id
        razon
        nit
        representante
        correo
        logo
        usuario
        estado
        ciudadPrincipal
        telefono
        direccion
        sedes {
          nextToken
        }
        createdAt
        updatedAt
      }
      aliadosSolicitudes {
        items {
          id
          nombre
          solicitudID
          aliadoID
          estado
          createdAt
          updatedAt
        }
        nextToken
      }
      archivosSolicitudes {
        items {
          id
          solicitudID
          nombre
          imagen
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAliadosSolicitud = /* GraphQL */ `
  mutation CreateAliadosSolicitud(
    $input: CreateAliadosSolicitudInput!
    $condition: ModelAliadosSolicitudConditionInput
  ) {
    createAliadosSolicitud(input: $input, condition: $condition) {
      id
      nombre
      solicitudID
      aliadoID
      estado
      solicitud {
        id
        nombre
        departamento
        estado
        createdAt
        updatedAt
      }
      aliado {
        id
        razon
        tipo_documento
        numero_documento
        telefono
        correo
        ciudad
        estado
        cobertura_nacional
        informacion_adicional
        aliadosCiudades {
          nextToken
        }
        aliadosSolicitudes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAliadosSolicitud = /* GraphQL */ `
  mutation UpdateAliadosSolicitud(
    $input: UpdateAliadosSolicitudInput!
    $condition: ModelAliadosSolicitudConditionInput
  ) {
    updateAliadosSolicitud(input: $input, condition: $condition) {
      id
      nombre
      solicitudID
      aliadoID
      estado
      solicitud {
        id
        nombre
        departamento
        estado
        createdAt
        updatedAt
      }
      aliado {
        id
        razon
        tipo_documento
        numero_documento
        telefono
        correo
        ciudad
        estado
        cobertura_nacional
        informacion_adicional
        aliadosCiudades {
          nextToken
        }
        aliadosSolicitudes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAliadosSolicitud = /* GraphQL */ `
  mutation DeleteAliadosSolicitud(
    $input: DeleteAliadosSolicitudInput!
    $condition: ModelAliadosSolicitudConditionInput
  ) {
    deleteAliadosSolicitud(input: $input, condition: $condition) {
      id
      nombre
      solicitudID
      aliadoID
      estado
      solicitud {
        id
        nombre
        departamento
        estado
        createdAt
        updatedAt
      }
      aliado {
        id
        razon
        tipo_documento
        numero_documento
        telefono
        correo
        ciudad
        estado
        cobertura_nacional
        informacion_adicional
        aliadosCiudades {
          nextToken
        }
        aliadosSolicitudes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createArchivosSolicitud = /* GraphQL */ `
  mutation CreateArchivosSolicitud(
    $input: CreateArchivosSolicitudInput!
    $condition: ModelArchivosSolicitudConditionInput
  ) {
    createArchivosSolicitud(input: $input, condition: $condition) {
      id
      solicitudID
      nombre
      imagen
      solicitud {
        id
        queNecesitas
        fechaEjecucion
        descripcion
        ciudad_sede
        sede
        usuario
        estado
        empresaID
        empresa {
          id
          razon
          nit
          representante
          correo
          logo
          usuario
          estado
          ciudadPrincipal
          telefono
          direccion
          createdAt
          updatedAt
        }
        aliadosSolicitudes {
          nextToken
        }
        archivosSolicitudes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateArchivosSolicitud = /* GraphQL */ `
  mutation UpdateArchivosSolicitud(
    $input: UpdateArchivosSolicitudInput!
    $condition: ModelArchivosSolicitudConditionInput
  ) {
    updateArchivosSolicitud(input: $input, condition: $condition) {
      id
      solicitudID
      nombre
      imagen
      solicitud {
        id
        queNecesitas
        fechaEjecucion
        descripcion
        ciudad_sede
        sede
        usuario
        estado
        empresaID
        empresa {
          id
          razon
          nit
          representante
          correo
          logo
          usuario
          estado
          ciudadPrincipal
          telefono
          direccion
          createdAt
          updatedAt
        }
        aliadosSolicitudes {
          nextToken
        }
        archivosSolicitudes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteArchivosSolicitud = /* GraphQL */ `
  mutation DeleteArchivosSolicitud(
    $input: DeleteArchivosSolicitudInput!
    $condition: ModelArchivosSolicitudConditionInput
  ) {
    deleteArchivosSolicitud(input: $input, condition: $condition) {
      id
      solicitudID
      nombre
      imagen
      solicitud {
        id
        queNecesitas
        fechaEjecucion
        descripcion
        ciudad_sede
        sede
        usuario
        estado
        empresaID
        empresa {
          id
          razon
          nit
          representante
          correo
          logo
          usuario
          estado
          ciudadPrincipal
          telefono
          direccion
          createdAt
          updatedAt
        }
        aliadosSolicitudes {
          nextToken
        }
        archivosSolicitudes {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPreferencias = /* GraphQL */ `
  mutation CreatePreferencias(
    $input: CreatePreferenciasInput!
    $condition: ModelPreferenciasConditionInput
  ) {
    createPreferencias(input: $input, condition: $condition) {
      id
      ciudad
      sede
      usuario
      createdAt
      updatedAt
    }
  }
`;
export const updatePreferencias = /* GraphQL */ `
  mutation UpdatePreferencias(
    $input: UpdatePreferenciasInput!
    $condition: ModelPreferenciasConditionInput
  ) {
    updatePreferencias(input: $input, condition: $condition) {
      id
      ciudad
      sede
      usuario
      createdAt
      updatedAt
    }
  }
`;
export const deletePreferencias = /* GraphQL */ `
  mutation DeletePreferencias(
    $input: DeletePreferenciasInput!
    $condition: ModelPreferenciasConditionInput
  ) {
    deletePreferencias(input: $input, condition: $condition) {
      id
      ciudad
      sede
      usuario
      createdAt
      updatedAt
    }
  }
`;
