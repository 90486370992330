import React, {useEffect} from "react";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar";
import { TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import "./SolicitudesEmpresa.css";

// Components
import FiltroSolicitudes from "./filtroSolicitudes/FiltroSolicitudes";
import TablaSolicitudes from "./tablaSolicitudes/TablaSolicitudes";
import { API, Auth } from "aws-amplify";

import moment from 'moment'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "white",
  },
  tabs: {
    backgroundColor: "white",
    boxShadow: "0px 3px 6px #00000029",
    paddingBottom: 8,
  },
  tabsPanels: {
    "& > div": {
      paddingTop: 24,
      paddingBottom: 24,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  tab: {
    color: "#707070",
    // "&:hover": {
    //   color: "#63BAB3",
    //   opacity: 1,
    // },
    // "&$selected": {
    //   color: "#63BAB3",
    //   fontWeight: "700",
    // },
    // "&:focus": {
    //   color: "#63BAB3",
    // },
  },
}));

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      width: "100%",
      backgroundColor: "#63BAB3",
    },
  },
  root: {
    textTransform: "none",
    minWidth: 72,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  selected: {},
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    color: "#707070",
    "&.Mui-selected": {
      color: "#63BAB3",
    },
  },
}))((props) => <Tab {...props} />);

export default function SolicitudesEmpresa(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    console.log('--',newValue)
    setValue(newValue);
  };

  useEffect(() => {    
    validateSession()
  }, []);

  async function validateSession() {
    let usuario;
    await Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
    .then((user) => {
      usuario = user;
    })
    .catch((err) => props.history.push("/admin/login"));

    const urlSearchParams = new URLSearchParams(window.location.search);
    const idObject = Object.fromEntries(urlSearchParams.entries());
    const nLength = Object.keys(idObject).length;
    if (nLength > 0){
      let t = parseInt(idObject.t);
      console.log(t)
      setValue(t);
    }

  }

  return (
    <div className={classes.root} id="SOLICITUDES-DE-LA-EMPRESA">
      <AppBar position="static" className={classes.tabs}>
        <div className="wrapper-general">
          <h2 className="fz-28 text-dark-1 fw-700 title-solicitud ">
            Solicitudes de la empresa
          </h2>

          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <StyledTab label="Recibidas" {...a11yProps(0)} />
            <StyledTab label="En proceso" {...a11yProps(1)} />
            <StyledTab label="Ejecutadas" {...a11yProps(2)} />
          </StyledTabs>
        </div>
      </AppBar>
      <TabPanel className={classes.tabsPanels} value={value} index={0}>
        <FiltroSolicitudes estado={value}></FiltroSolicitudes>
        <TablaSolicitudes estado={value}></TablaSolicitudes>
      </TabPanel>
      <TabPanel className={classes.tabsPanels} value={value} index={1}>
        <FiltroSolicitudes estado={value}></FiltroSolicitudes>
        <TablaSolicitudes estado={value}></TablaSolicitudes>
      </TabPanel>
      <TabPanel className={classes.tabsPanels} value={value} index={2}>
        <FiltroSolicitudes estado={value}></FiltroSolicitudes>
        <TablaSolicitudes estado={value}></TablaSolicitudes>
      </TabPanel>
    </div>
  );
}
