import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { API, graphqlOperation, Auth } from "aws-amplify";
import { getSolicitudes,  getSedes } from "./../../../../../../graphql/queries";
import moment from 'moment'




let initialSolicitud = {
  createdAt: '',
  id: '',
  queNecesitas: '',
  descripcion: '',
  ciudad_sede: '',
  sede: '',
  usuario: '',
  estado: '',
  id: '',
  sede: '',
  empresa: {}
}

let initialEmpresa = {
  correo:'',
  direccion: '',
  telefono: ''
}

let initialUser = {
  name:''
}

function InformacionSolicitud({setEstadoGlobal}) {
  const [solicitudData, setSolicitudData] = useState(initialSolicitud)
  const [user, setuser] = useState(initialUser)
  const [sede, setSede] = useState(initialEmpresa)
  const [estado, setEstado] = useState("")
  const [usuario, setUsuario] = useState("")

  useEffect(() =>{
    getSolicitudInitial()
    
  },[])

  const getSolicitudInitial = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const idObject = Object.fromEntries(urlSearchParams.entries());
    const nLength = Object.keys(idObject).length;
    if (nLength > 0) {
      let idSolicitud = idObject.id;
      const solicitudDataStorage = await API.graphql(
        graphqlOperation(getSolicitudes, { id: idSolicitud })
      ).then(a => {        
        setSolicitudData(a.data.getSolicitudes)
        //console.log('Se recibe',a.data.getSolicitudes.estado)
        setEstadoGlobal(a.data.getSolicitudes.estado)
        convertEstado(a.data.getSolicitudes.estado)
        getUser(a.data.getSolicitudes.usuario)
        getEmpresas(a.data.getSolicitudes.sede) 
      });
      //console.log(sede)
    } else {
      console.log("No tiene un ID Valido");
    }
  };


  
  const getEmpresas = async (sede) => {
    const empresaData = await API.graphql(
      graphqlOperation(getSedes, { id: sede })
    ); 
    
    setSede(empresaData.data.getSedes)
    
  };

  const getUser = async (id) => {
    let apiName = "AdminQueries";
    let path = "/getUser";
    let myInit = {
      queryStringParameters: {
        username: id,
        groupname: "usuarios",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    let q = await API.get(apiName, path, myInit);
    setUsuario(q.UserAttributes.find(a => a.Name == 'email').Value)
    
  };

  const convertEstado = (estado) => {
    
    switch(estado){
      case "0":
        setEstado("Recibida")
        break;
      case "1":
        setEstado("En Proceso")
        break;
      case "2":
        setEstado("Ejecutada")
        break;
    }
  }
  
  return (
    <Fragment>
      <div className="row mx-auto">
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
          <Link
            to="/admin/solicitudes"
            className="btn-finger fz-24 text-blue-ligth "
          >
            <i className="fal fa-chevron-left"></i>
          </Link>
          <h2 className="text-blue-ligth fz-28 fw-700 w-100">Solicitud</h2>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <p className="text-blue-ligth mb-0">Recibida</p>
          <p className="text-dark-1 text-truncate">{solicitudData.createdAt == undefined ? '' : moment(solicitudData.createdAt).format('YYYY/MM/DD hh:mm:ss a') }</p>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <p className="text-blue-ligth mb-0">Ejecutada</p>
          <p className="text-dark-1 text-truncate">{solicitudData.fechaEjecucion === null ? '-' : moment(solicitudData.fechaEjecucion).format('YYYY/MM/DD hh:mm:ss a') }</p>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <p className="text-blue-ligth mb-0">Estado</p>
          <p className="text-dark-1 text-truncate">{estado}</p>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <p className="text-blue-ligth mb-0">ID Solicitud</p>
          <p className="text-dark-1 text-truncate">{solicitudData.id === undefined ? '-':  solicitudData.id.substring(solicitudData.id.length - 3, solicitudData.id.length)}</p>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <p className="text-blue-ligth mb-0">Usuario</p>
          <p className="text-dark-1 text-truncate">{usuario}</p>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <p className="text-blue-ligth mb-0">Correo Empresa</p>
          <p className="text-dark-1 text-truncate">{solicitudData.empresa.correo}</p> 
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <p className="text-blue-ligth mb-0">Celular</p>
          <p className="text-dark-1 text-truncate">{solicitudData.empresa.telefono}</p>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <p className="text-blue-ligth mb-0">Ciudad</p>
          <p className="text-dark-1 text-truncate">{sede.ciudad}</p>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <p className="text-blue-ligth mb-0">Dirección</p>
          <p className="text-dark-1 text-truncate">{sede.direccion}</p>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <p className="text-blue-ligth mb-0">Sede</p>
          <p className="text-dark-1 text-truncate">{sede.nombre}</p>
        </div> 
      </div>
      <div className="row mx-auto">
        <div className="col-md-12">
          <p className="text-blue-ligth mb-0">Descripción</p>
          <p className="text-dark-1 ">
          {solicitudData.descripcion}
          </p>
        </div>
      </div>
    </Fragment>
  );
}

export { InformacionSolicitud };
