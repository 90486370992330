import { useState, useEffect, Fragment } from "react";
import { API, graphqlOperation, Storage, Auth } from "aws-amplify";
import { getSolicitudes } from "./../../../../../../graphql/queries";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { createArchivosSolicitud } from "./../../../../../../graphql/mutations";



const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },

    "& > *": {
      margin: theme.spacing(1),
    },
  },

  input: {
    display: "none",
  },



}));


function ArchivosAdjuntos({imagenes, setImagenes,actualiza}) {
  const classes = useStyles();
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [document, setDocument] = useState([]);

  useEffect(() =>{    
    getAdjuntos()
  },[actualiza])


  //ChangeImage
  const handleChangeimages = async ({ target }) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const idObject = Object.fromEntries(urlSearchParams.entries())
    const nLength = Object.keys(idObject).length;
    let idSolicitud = idObject.id;
    const file = target.files[0];
    console.log('fie', file)
    if(file !== undefined){
      const url = URL.createObjectURL(file);
      let img = {
        file,
        url,
        name: file.name,
      };
      // setImages([...images, img]);
      let numB = Math.floor(Math.random() * 100000)
      let nombreFile;
      await Storage.put(numB + img.name, img.file, {
      })
      .then((result) => {
        console.log('se sube',result.key);
        nombreFile = result.key
      })
      .catch((err) => {
        console.log(err);
        // setCargando(false);
      });
      //Se registran los archivos
      let sendDataSolicitudArchivos = {
        input: {
          solicitudID: idSolicitud,
          nombre: nombreFile,
          imagen: false
        },
      };
      await API.graphql(graphqlOperation(createArchivosSolicitud, sendDataSolicitudArchivos))
      .then((result) => {
        //console.log("Se crea SolicitudArchivos", result);
        getAdjuntos()
      })
      .catch((err) => {
        console.log("Error de la operacion de creacion ", err);
        // setCargando(false);
      });
    }
    
  }

  const getAdjuntos = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const idObject = Object.fromEntries(urlSearchParams.entries());
    const nLength = Object.keys(idObject).length;
    if (nLength > 0){
      let idSolicitud = idObject.id;
      const solicitudData = await API.graphql(
        graphqlOperation(getSolicitudes, { id: idSolicitud })
      );
      console.log('--',solicitudData)
     
      let arrayFile = [];
      let arrayImages = [];
      const resp = await Promise.all(        
        solicitudData.data.getSolicitudes.archivosSolicitudes.items.map(async a => {      
          const signedURL = await Storage.get(a.nombre); // get key from Storage.list
          if(!a.imagen) {
            arrayFile.push(signedURL);
          }else{
            arrayImages.push(signedURL);
          }
        })        
      );
      setDocument(arrayFile)
      setImagenes(arrayImages)
    }
  }
  return (
    <Fragment>
      <p className="text-blue-ligth fw-700 mb-3">Archivos Adjuntos</p>
      <div className="list-content mb-4">
        {
          document.map((a, b) => (
            <div className="list-content-item" key={b}>
              <div className="d-flex align-items-center">
                <div className="btn-finger fz-24 text-dark-1 me-1">
                  <i className="fal fa-file-alt"></i>
                </div>
                <p className="text-dark-1 fz-14 mb-0 d-inline-block text-truncate ">
                  Documento Adjunto {b + 1}
                </p>
              </div>
              <div className="d-flex flex-column">
                <div className="btn-finger fz-24 text-blue-ligth">
                  <a href={a}>
                  <i className="fal fa-download"></i></a>
                </div>
              </div>
            </div>
          ))
        }
        
      </div>
      <div className="row mx-auto d-flex justify-content-between align-items-center mb-48 flex-wrap">
              <input
                accept=".pdf"
                className={classes.input}
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleChangeimages}
              />

              <label htmlFor="contained-button-file">
                <IconButton
                  component="span"
                  className={classes.margin}
                  size="small"
                >
                  <div
                    className="btn-custom bg-blue-ligth text-white box-shadow-1 fz-14 fw-700 position-relative mb-2"
                    style={{ maxWidth: 200 }}
                  >
                    ADJUNTAR ARCHIVO
                  </div>
                </IconButton>
              </label>
        
        <button
          className="btn-custom bg-blue-dark text-white box-shadow-1 fz-14 fw-700 position-relative mb-2"
          style={{ maxWidth: 320 }}
        >
          DESCARGAR TODOS LOS ARCHIVOS
        </button>
      </div>
    </Fragment>
  );
}
export { ArchivosAdjuntos };
