import React, { Component } from 'react';
import { Link } from "react-router-dom";
import "./Card.css";

class Card extends Component {
  render() {
    return (
        <div className="mt-4">
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">{this.props.titulo}</h5>
                    <h6 className="card-subtitle mb-4px">{this.props.subtitulo}</h6>
                    <h6 className="card-subtitle mb-12">{this.props.estado}: {this.props.fecha} {this.props.hora}</h6>
                    <h5 className="card-title2 mb-12">Descripción de la solicitud</h5>
                    <p className="card-text mb-8">{this.props.descripcion}</p>
                <div className="separador mb-16"/>
                    <div className="link-block">
                        <Link to={`ver-solicitud/?id=${this.props.id}`}>
                            <button type="button" className="btn bg-yellow-0 bs-2 fs-14 w-100-p mh-48 color-white-1 br-30 py-13 px-3">VER SOLICITUD</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default Card;