import { useEffect, useState } from "react";
import "./Sede.css";

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import { TextField } from "@material-ui/core";

import { API, Auth, graphqlOperation } from "aws-amplify";
import { updateSedes, createSedes } from "./../../../../../graphql/mutations";
import Autocomplete from "@material-ui/lab/Autocomplete";
import citys from "./../../../../ciudades.json";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: "blur(4px)",
  },
  paper: {
    backgroundColor: "white",
    borderRadius: 12,
    width: 632,
    padding: 36,
  },
  TextFieldMultinea: {
    width: "100%",
    border: "1px solid #63BAB3",
    borderRadius: 11,
    "& > div::before": {
      display: "none",
    },
    "& > div::after": {
      display: "none",
    },
    "& > label.MuiInputLabel-shrink": {
      transform: "translate(0, 16px) scale(0.75)",
    },
  },
}));

const initalForm = {
  nombre: "",
  ciudad: "",
  direccion: "",
  telefono: "",
  informacion: "",
};

export default function Sede({ sedes, setSedes, creacionAutomatica }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [formState, setFormState] = useState(initalForm);

  useEffect(() => {}, []);

  const handleOpen = () => {
    setFormState(initalForm);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  async function addSede() {
    let sedesActuales = sedes;
    sedesActuales.push(formState);
    setSedes(sedesActuales);
    if (creacionAutomatica) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const idObject = Object.fromEntries(urlSearchParams.entries());
      const nLength = Object.keys(idObject).length;
      let idEmpresa = idObject.id;

      let usuario;
      await Auth.currentAuthenticatedUser({
        bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      })
        .then((user) => {
          usuario = user;
        })
        .catch((err) => alert("Deberia de Iniciar Sesion"));
      let sendDataSede = {
        input: {
          ciudad: formState.ciudad,
          direccion: formState.direccion,
          telefono: formState.telefono,
          informacion: formState.informacion,
          principal: false,
          nombre: formState.nombre.toUpperCase(),
          usuario: usuario.username,
          estado: true,
          empresaID: idEmpresa,
        },
      };
      await API.graphql(graphqlOperation(createSedes, sendDataSede))
        .then((result) => {
          //console.log('Se crea Sucursal', result)
          //  setCargando(false);
        })
        .catch((err) => {
          console.log("Error de la operacion de creacion ", err);
          //setCargando(false);
        });
    }
    setOpen(false);
  }

  async function deleteSede(id) {
    // console.log("Eliminar", id);
    let sedeEliminar = sedes.find((a) => a.nombre === id);
    // console.log("Eliminar", sedeEliminar);
    //Validar que tenga ID, si Tiene eliminar de BD
    if (sedeEliminar.hasOwnProperty("id")) {
      let sendData = {
        query: updateSedes,
        variables: {
          input: {
            id: sedeEliminar.id,
            estado: false,
          },
        },
      };
      await API.graphql(sendData)
        .then((result) => {
          console.log("Se actualiza Sede Principal", result);
        })
        .catch((err) => {
          console.log("error sede", err);
          alert("Ocurrio un error");
        });
    }
    let sedesNuevas = sedes.filter((a) => a.nombre !== id);

    setSedes(sedesNuevas);
  }

  return (
    <>
      <div className="w-100 bg-blue-ligth-2 mb-44">
        <div className="wrapper-general ">
          <h2 className="text-blue fw-700 fz-20 py-12 mb-0">
            Sedes de la empresa
          </h2>
        </div>
      </div>

      <div className="wrapper-general mb-24">
        <div className="list-content ">
          {!sedes.length ? (
            <h2 className="text-gray-5 text-center fw-700 fz-24 m-auto">
              No hay sedes para mostrar
            </h2>
          ) : null}
          {sedes.map((a, b) => (
            <div className="list-content-item" key={b}>
              <div className="d-flex flex-column">
                <div className="btn-finger fz-24 text-dark-1">
                  <i className="fal fa-building "></i>
                </div>
              </div>
              <div className="d-flex flex-column">
                <p className="text-blue-ligth fz-12 mb-1">Nombre de la sede</p>
                <p
                  className="text-dark-1 fz-14 mb-0 d-inline-block text-truncate "
                  style={{ maxWidth: 152, minWidth: 152 }}
                >
                  {a.nombre}
                </p>
              </div>
              <div className="d-flex flex-column">
                <p className="text-blue-ligth fz-12 mb-1">Dirección</p>
                <p
                  className="text-dark-1 fz-14 mb-0 d-inline-block text-truncate "
                  style={{ maxWidth: 152, minWidth: 152 }}
                >
                  {a.direccion}
                </p>
              </div>
              <div className="d-flex flex-column">
                <p className="text-blue-ligth fz-12 mb-1">Teléfono</p>
                <p
                  className="text-dark-1 fz-14 mb-0 d-inline-block text-truncate "
                  style={{ maxWidth: 108, minWidth: 108 }}
                >
                  {a.telefono}
                </p>
              </div>
              <div className="d-flex flex-column">
                <p className="text-blue-ligth fz-12 mb-1">Ciudad</p>
                <p
                  className="text-dark-1 fz-14 mb-0 d-inline-block text-truncate "
                  style={{ maxWidth: 104, minWidth: 104 }}
                >
                  {a.ciudad}
                </p>
              </div>
              <div className="d-flex flex-column">
                <p className="text-blue-ligth fz-12 mb-1">
                  Información adicional
                </p>
                <p
                  className="text-dark-1 fz-14 mb-0 d-inline-block text-truncate "
                  style={{ maxWidth: 292, minWidth: 292 }}
                >
                  {a.informacion}
                </p>
              </div>
              <div className="d-flex flex-column">
                <div
                  className="btn-finger fz-24 text-red"
                  onClick={(event) => deleteSede(a.nombre)}
                >
                  <i className="far fa-trash-alt"></i>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="wrapper-general mb-28">
        <div className="row mx-auto justify-content-xl-end justify-content-center">
          {/* <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 mb-2">
            <button className="btn-custom bg-blue-dark text-white fz-14 fw-700 box-shadow-1">
              SUBIR EXCEL
            </button>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 mb-2">
            <button className="btn-custom bg-blue text-white fz-14 fw-700 box-shadow-1">
              BAJAR PLANTILLA
            </button>
          </div> */}
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-2">
            <button
              onClick={handleOpen}
              className="btn-custom bg-blue-ligth text-white fz-14 fw-700 box-shadow-1"
            >
              AGREGAR NUEVA SEDE
            </button>
          </div>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 className="fz-28 fw-700 text-dark-1 text-center mb-20">
              Añadir Sede
            </h2>
            <div className="row mx-auto">
              <div className="col-md-6 mb-16">
                <TextField
                  id="razon"
                  label="Nombre de la Sede"
                  className="text-fiel-custom-blue"
                  onChange={(event) => setInput("nombre", event.target.value)}
                  value={formState.nombre}
                />
              </div>
              <div className="col-md-6 mb-16">
                <TextField
                  id="razon"
                  label="Dirección"
                  className="text-fiel-custom-blue"
                  onChange={(event) =>
                    setInput("direccion", event.target.value)
                  }
                  value={formState.direccion}
                />
              </div>
              <div className="col-md-6 mb-16">
                <Autocomplete
                    id="Aliado"
                    options={citys}
                    getOptionLabel={(option) => option}
                    autoComplete={false}
                    onChange={(event, newValue) => {
                      setInput("ciudad", newValue)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Ciudad"
                        className="text-fiel-custom-blue"
                        autoComplete="chrome-off"
                        id="ciudad"
                      />
                    )}
                  />

                
              </div>
              <div className="col-md-6 mb-16">
                <TextField
                  id="razon"
                  label="Teléfono"
                  className="text-fiel-custom-blue"
                  onChange={(event) => setInput("telefono", event.target.value)}
                  value={formState.telefono}
                />
              </div>
              <div className="col-md-12 mb-24">
                <TextField
                  id="razon"
                  label="Información adicional"
                  className={classes.TextFieldMultinea}
                  multiline
                  rows={4}
                  onChange={(event) =>
                    setInput("informacion", event.target.value)
                  }
                  value={formState.informacion}
                />
              </div>
              <div className="col-md-4 offset-lg-2">
                <button
                  onClick={handleClose}
                  className="btn-custom bg-blue-dark text-white fz-16 fw-700 position-relative "
                >
                  <div className="btn-finger text-white fz-24 icon-button-start">
                    <i className="fal fa-times"></i>
                  </div>
                  CANCELAR
                </button>
              </div>
              <div className="col-md-4">
                <button
                  onClick={addSede}
                  className="btn-custom bg-yellow text-white fz-16 fw-700 position-relative "
                >
                  <div className="btn-finger text-white fz-24 icon-button-start">
                    <i className="fal fa-building "></i>
                  </div>
                  AÑADIR
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
