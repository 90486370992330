import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Redirect } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import citys from "./../../../../ciudades.json";

import "./CreateBusiness.css";

//AWS
import { API, graphqlOperation, Auth, Storage } from "aws-amplify";
import { createEmpresa, createSedes } from "./../../../../../graphql/mutations";

// Components
import Sede from "./../sedeEmpresa/Sede";

const useStyles = makeStyles((theme) => ({}));

const initalForm = {
  razonSocial: "",
  nit: "",
  representanteLegal: "",
  correo: "",
  keyLogo: "",
};
const initalFormPrincipal = {
  nombre: "Sede Principal",
  ciudad: "",
  direccion: "",
  telefono: "",
  informacion: "",
};

const initialFile = {
  odified: "",
  lastModifiedDate: {},
  name: "",
  size: "",
  type: "",
  webkitRelativePath: "",
};

export default function CreateBusiness() {
  const classes = useStyles();
  const [formState, setFormState] = useState(initalForm);
  const [formStatePrincipal, setFormStatePrincipal] = useState(
    initalFormPrincipal
  );
  const [sedes, setSedes] = useState([]);
  const [archivo, setArchivo] = useState(initialFile);
  const [image, setImage] = useState("");
  const [cargando, setCargando] = useState(false);
  const [redirigir, setRedirigir] = useState(false);

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  function setInputPrincipal(key, value) {
    setFormStatePrincipal({ ...formStatePrincipal, [key]: value });
  }

  async function setInputFile(files) {
    const file = files[0];
    let url = URL.createObjectURL(file);
    if (/\.(jpg|png|gif)$/i.test(file.name)) {
      setArchivo(file);
      setImage(url);
    }
  }

  async function crearEmpresa() {

    setCargando(true);
    let usuario;
    await Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => {
        usuario = user;
      })
      .catch((err) => alert("Deberia de Iniciar Sesion"));

    //Iniciamos Validaciones de Formulario
    if (
      formState.razonSocial.trim() === "" ||
      formState.nit.trim() === "" ||
      formState.representanteLegal.trim() === "" ||
      formState.correo.trim() === "" ||
      formStatePrincipal.ciudad.trim() === "" ||
      formStatePrincipal.direccion.trim() === "" ||
      formStatePrincipal.telefono.trim() === ""
    ) {
      setCargando(false);
      alert("Campos Vacios");
      return;
    }
    let fileUploaded = "";
    if (archivo != undefined) {
      await Storage.put(archivo.name, archivo, {
        /*progressCallback(progress) {
          setPorUpload((progress.loaded * 100)/ progress.total)
        }*/
      })
        .then((result) => {
          fileUploaded = result.key;
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    let sendDataEmpresa = {
      input: {
        razon: formState.razonSocial.toUpperCase(),
        nit: formState.nit,
        representante: formState.representanteLegal,
        correo: formState.correo,
        logo: fileUploaded,
        usuario: usuario.username,
        estado: true,
        ciudadPrincipal: formStatePrincipal.ciudad,
        telefono: formStatePrincipal.telefono,
        direccion: formStatePrincipal.direccion,
      },
    };

    let empresaCreada;
    await API.graphql(graphqlOperation(createEmpresa, sendDataEmpresa))
      .then((result) => {
        empresaCreada = result.data.createEmpresa;
        console.log("Se crea Empresa", empresaCreada);
      })
      .catch((err) => {
        console.log("Error de la operacion de creacion ", err);
        setCargando(false);
      });

    //Creacion de Sede Principal
    let sendDataDesdePrincipal = {
      input: {
        ciudad: formStatePrincipal.ciudad,
        direccion: formStatePrincipal.direccion,
        telefono: formStatePrincipal.telefono,
        informacion: formStatePrincipal.informacion,
        principal: true,
        nombre: formStatePrincipal.nombre.toUpperCase(),
        usuario: usuario.username,
        estado: true,
        empresaID: empresaCreada.id,
      },
    };

    await API.graphql(graphqlOperation(createSedes, sendDataDesdePrincipal))
      .then((result) => {
        //console.log('Se crea Sede Principal', result)
        crearSede(usuario, empresaCreada.id);
        setFormState(initalForm);
        setFormStatePrincipal(initalFormPrincipal);

        setCargando(false);
      })
      .catch((err) => {
        console.log("Error de la operacion de creacion ", err);
        setCargando(false);
      });
  }

  async function crearSede(usuario, empresaId) {
    setCargando(true);
    //console.log('Sedes', sedes);
    let sendDataSede;

    const resp = await Promise.all(
      sedes.map(async (a, b) => {
        sendDataSede = {
          input: {
            ciudad: a.ciudad,
            direccion: a.direccion,
            telefono: a.telefono,
            informacion: a.informacion,
            principal: false,
            nombre: a.nombre.toUpperCase(),
            usuario: usuario.username,
            estado: true,
            empresaID: empresaId,
          },
        };
        await API.graphql(graphqlOperation(createSedes, sendDataSede))
          .then((result) => {
            //console.log('Se crea Sucursal', result)
            setCargando(false);
          })
          .catch((err) => {
            console.log("Error de la operacion de creacion ", err);
            setCargando(false);
          });
      })
    );
    setCargando(false);
    setSedes([]);
    setRedirigir(true);
    //redirigir

    //console.log('Se termina proceso de creacion')
  }

  const addUsuario = async () => {
    let arrayTotal = [
      {
          "Nombre": "RAUL SANTIAGO BOTERO JARAMILLO",
          "Correo": "santiago@finsocial.co"
      },
      {
          "Nombre": "JORGE ALBERTO VARGAS MEJIA ",
          "Correo": "jorge.vargas@finsocial.co"
      },
      {
          "Nombre": "CLAUDIA PATRICIA GONZALEZ GOMEZ",
          "Correo": "claudia.gonzalez@finsocial.co"
      },
      {
          "Nombre": "JOSE FERNANDO CASTRO CUELLO",
          "Correo": "jose.castro@finsocial.co"
      },
      {
          "Nombre": "DANIELA CAROLINA BULA HERNANDEZ",
          "Correo": "daniela.bula@finsocial.co"
      },
      {
          "Nombre": "JOHANNA MARCELA RESTREPO BAENA ",
          "Correo": "johanna.restrepo@finsocial.co"
      },
      {
          "Nombre": "JAKELINE JAIME MARTINEZ",
          "Correo": "contabilidad@finsocial.co"
      },
      {
          "Nombre": "GUSTAVO ADOLFO SIMMONDS SARMIENTO ",
          "Correo": "gustavo@finsocial.co"
      },
      {
          "Nombre": "JUAN MANUEL PUERTO ANZOLA",
          "Correo": "juan.puerto@finsocial.co"
      },
      {
          "Nombre": "LEONARD ESTARITA CASTILLA",
          "Correo": "leonard.estarita@finsocial.co"
      },
      {
          "Nombre": "CAROLINA VELEZ ESCOBAR",
          "Correo": "carolina.velez@finsocial.co"
      },
      {
          "Nombre": "JAIRO ASTROZ AVELLANEDA",
          "Correo": "gerente.riesgos@finsocial.co"
      },
      {
          "Nombre": "LILIANA MARIA SALDARRIAGA CADAVID",
          "Correo": "liliana.saldarriaga@finsocial.co"
      },
      {
          "Nombre": "DIANA MARCELA BARROS ROJAS",
          "Correo": "gerente.juridica@finsocial.co"
      },
      {
          "Nombre": "JESSICA MILENA PEREZ CARREÑO",
          "Correo": "gerente.mercadeo@finsocial.co"
      },
      {
          "Nombre": "BERTHA EUGENIA BALADI RODRIGUEZ",
          "Correo": "bertha.baladi@finsocial.co"
      },
      {
          "Nombre": "ALBERTO MARIO DIAZ MANOTAS",
          "Correo": "alberto.diaz@finsocial.co"
      },
      {
          "Nombre": "SANDRA MILENA PERTUZ CASTRO ",
          "Correo": "auxiliar.contable@finsocial.co"
      },
      {
          "Nombre": "LILIBETH DOLORES SUAREZ PACHECO",
          "Correo": "lilibeth.suarez@finsocial.co"
      },
      {
          "Nombre": "CINDY JOHANA ALVAREZ MENDOZA",
          "Correo": "gestion.humana@finsocial.co"
      },
      {
          "Nombre": "SILVANA MARCELA PANESSO BOLIVAR ",
          "Correo": "silvana.panesso@finsocial.co"
      },
      {
          "Nombre": "ROSSANA AYALA LARA ",
          "Correo": "roxana.ayala@finsocial.co"
      },
      {
          "Nombre": "GUSTAVO MALDONADO DIAZ ",
          "Correo": "gustavo.maldonado@finsocial.co"
      },
      {
          "Nombre": "RUTH MARIA MARQUEZ PEREZ ",
          "Correo": "ruth.marquez@finsocial.co"
      },
      {
          "Nombre": "BEATRIZ EUGENIA AGUIRRE BARONA ",
          "Correo": "beatriz.aguirre@finsocial.co"
      },
      {
          "Nombre": "STEFFANIA MALDONADO RAMOS",
          "Correo": "steffania.maldonado@finsocial.co"
      },
      {
          "Nombre": "GUSTAVO ADOLFO SUAREZ RODRIGUEZ",
          "Correo": "gustavo.suarez@finsocial.co"
      },
      {
          "Nombre": "NELSON DANIEL RAMIREZ",
          "Correo": "daniel.ramirez@finsocial.co"
      },
      {
          "Nombre": "PAOLA TATIANA ACUÑA BARRIOS",
          "Correo": "paola.acuna@finsocial.co"
      },
      {
          "Nombre": "PAULO CESAR ARENAS ARENAS",
          "Correo": "paulo.arenas@finsocial.co"
      },
      {
          "Nombre": "NANCY MARIA CORREA VARGAS",
          "Correo": "director.medellin@finsocial.co"
      },
      {
          "Nombre": "IDALBERTO ENRIQUE APONTE VALVERDE",
          "Correo": "idalberto.aponte@finsocial.co"
      },
      {
          "Nombre": "FRANCISCO JAVIER ZUÑIGA CELIS",
          "Correo": "director.ibague@finsocial.co"
      },
      {
          "Nombre": "LUIS FERNANDO ARBELAEZ RAMIREZ",
          "Correo": "director.ejecafetero@finsocial.com"
      },
      {
          "Nombre": "HEIDY ESTHER GALLO URUETA",
          "Correo": "coordinador.calidad@finsocial.co"
      },
      {
          "Nombre": "ERICA MARIA MAURY CABARCAS",
          "Correo": "erika.maury@finsocial.co"
      },
      {
          "Nombre": "LUIS JAVIER QUINTERO DAZA",
          "Correo": "operaciones.comerciales@finsocial.co"
      },
      {
          "Nombre": "DIANA MARCELA BERNAL IBAÑEZ",
          "Correo": "director.capacitacion@finsocial.co"
      },
      {
          "Nombre": "MONICA LILIANA PEREZ NIÑO",
          "Correo": "director.bucaramanga@finsocial.co"
      },
      {
          "Nombre": "CLAUDIA PATRICIA SALAZAR DE LA CRUZ",
          "Correo": "coordinador.riesgos@finsocial.co"
      },
      {
          "Nombre": "PEDRO VICENTE ABADIA VALLEJO",
          "Correo": "director.cali@finsocial.co"
      },
      {
          "Nombre": "CARLOS HUMBERTO HOLGUIN BENITEZ",
          "Correo": "director.popayan@finsocial.co"
      },
      {
          "Nombre": "XENIA ANGELINA MARTINEZ PATERNINA",
          "Correo": "director.comercial.baq@finsocial.co"
      },
      {
          "Nombre": "YOSELYN EUGENIA MANCO PATERNOSTRO",
          "Correo": "directora.fidelizacion@finsocial.co"
      },
      {
          "Nombre": "EDUARDO REINOSO QUINTERO",
          "Correo": "director.villavicencio@finsocial.co"
      },
      {
          "Nombre": "NAYLA VANESSA VILLADIEGO BETTIN",
          "Correo": "nayla@tutiendaencasa.com.co"
      },
      {
          "Nombre": "DIEGO ALEJANDRO RIOS PAREDES",
          "Correo": "director.pasto@finsocial.co"
      },
      {
          "Nombre": "AMANDA GUEVARA CRUZ",
          "Correo": "amanda@tutiendaencasa.com.co"
      },
      {
          "Nombre": "MARIAM MARGARITA MELENDEZ TOLOZA",
          "Correo": "directora.juridico@finsocial.co"
      },
      {
          "Nombre": "JULIAN GANE FORERO",
          "Correo": "julian.gane@finsocial.c"
      }
    ]

    let arrayPrueba = [
        {
          "Nombre": "EDUARDO ENRIQUE CASTRO CUELLO",
          "Correo": "eduardo.castro@finsocial.co"
        }
    ]
    let pass = "FinsocialMiPasion123*"
    let idEmpresa = 'c86b6483-3632-4766-86cd-8afa30bf9845'
    const respFiles = await Promise.all(
      arrayTotal.map(async (usuario, b) => {
        await Auth.signUp({
          username: usuario.Correo.trim(),
          password: pass,
          attributes: {
            email: usuario.Correo.trim(),
            phone_number: "+573002056050",
            "custom:empresa": idEmpresa,
          },
        })
        .then((result) => {
          console.log('creado', result)
          //addToGroup();
        })
        .catch((err) => {
          switch (err.code) {
            case "UsernameExistsException":
              alert("Ya el nombre de usuario existe");
              break;
          }
          console.log("error usuario", err);
        });          
      })
    );

    const respFiles2 = await Promise.all(
      arrayTotal.map(async (usuario, b) => {
        let apiName = "AdminQueries";
        let path = "/addUserToGroup";
        let myInit = {
          body: {
            username: usuario.Correo.trim(),
            groupname: "usuarios",
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          },
        };
        await API.post(apiName, path, myInit).then((result) => {
          console.log('creado2', result)
          //addToGroup();
        })
        .catch((err) => {

          console.log("error usuario", err);
        });          
      })
    );

  };

  if (redirigir) {
    return <Redirect to="/admin/empresas" />;
  }

  return (
    <Fragment>
      <div className="wrapper-general pt-4 position-relative mb-28">
        <div className="d-flex jusitfy-content-between align-items-center ">
          <Link
            to="/admin/empresas"
            className="btn-finger fz-24 text-blue-ligth position-absolute"
          >
            <i className="fal fa-chevron-left"></i>
          </Link>
          <h2 className="text-dark-1 fz-28 fw-700 title-business text-center w-100">
            Crear nueva empresa
          </h2>
        </div>
      </div>

      <div className="w-100 bg-blue-ligth-2 mb-44">
        <div className="wrapper-general ">
          <h2 className="text-blue fw-700 fz-20 py-12 mb-0">
            Información de la empresa
          </h2>
        </div>
      </div>

      <div className="wrapper-general mb-44">
        <div className="row mx-0">
          <div className="col-lg-4 col-md-6 px-2 mb-12">
            <TextField
              id="razon"
              label="Razón Social"
              className="text-fiel-custom-blue"
              onChange={(event) => setInput("razonSocial", event.target.value)}
              value={formState.razonSocial}
            />
          </div>
          <div className="col-lg-4 col-md-6 px-2 mb-12">
            <TextField
              id="nit"
              label="Nit"
              className="text-fiel-custom-blue"
              onChange={(event) => setInput("nit", event.target.value)}
              value={formState.nit}
            />
          </div>
          <div className="col-lg-4 col-md-6 px-2 mb-12">
            <TextField
              id="representante"
              label="Representante legal"
              className="text-fiel-custom-blue"
              onChange={(event) =>
                setInput("representanteLegal", event.target.value)
              }
              value={formState.representanteLegal}
            />
          </div>
          <div className="col-lg-4 col-md-6 px-2 mb-12">
                <Autocomplete
                  id="Aliado"
                  options={citys}
                  getOptionLabel={(option) => option}
                  autoComplete={false}
                  onChange={(event, newValue) => {
                    setInputPrincipal("ciudad", newValue)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Ciudad Sede Principal"
                      className="text-fiel-custom-blue"
                      autoComplete="chrome-off"
                      id="ciudad"
                    />
                  )}
                />

           
          </div>
          <div className="col-lg-4 col-md-6 px-2 mb-12">
            <TextField
              id="direccion"
              label="Dirección Sede Principal"
              className="text-fiel-custom-blue"
              onChange={(event) =>
                setInputPrincipal("direccion", event.target.value)
              }
              value={formStatePrincipal.direccion}
            />
          </div>
          <div className="col-lg-4 col-md-6 px-2 mb-12">
            <TextField
              id="telefono"
              label="Teléfono Sede Principal"
              className="text-fiel-custom-blue"
              onChange={(event) =>
                setInputPrincipal("telefono", event.target.value)
              }
              value={formStatePrincipal.telefono}
            />
          </div>
          <div className="col-lg-4 col-md-6 px-2 mb-12">
            <TextField
              id="correo"
              label="Correo de contacto"
              className="text-fiel-custom-blue"
              onChange={(event) => setInput("correo", event.target.value)}
              value={formState.correo}
            />
          </div>
          <div className="col-lg-8 col-md-12 px-2 mb-12">
            <input
              accept="image/*"
              className="d-none"
              id="contained-button-file"
              multiple
              type="file"
              onChange={(event) => {
                setInputFile(event.target.files);
              }}
            />
            <div className="row mx-auto">
              <div className="col-1 px-0">
                <label
                  className="circle-logo-brand"
                  htmlFor="contained-button-file"
                >
                  <img src={image} alt={archivo.name} />
                </label>
              </div>
              <div className="col-11 pe-0">
                <TextField
                  id="correo"
                  label="Logo de la empresa"
                  className="text-fiel-custom-blue"
                  value={archivo.name}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Sede sedes={sedes} setSedes={setSedes} creacionAutomatica={false} />

      <div className="w-100 bg-blue-ligth-2">
        <div className="wrapper-general border-top-gray">
          <div className="row mx-auto justify-content-lg-between justify-content-center pt-28 pb-32">
            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-12 mb-2">
              <button
                className="btn-custom bg-blue-dark text-white fz-16 fw-700 position-relative "
                onClick={(event) => setRedirigir(true)}
              >
                <div className="btn-finger text-white fz-24 icon-button-start">
                  <i className="fal fa-times"></i>
                </div>
                CANCELAR
              </button>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-2">
              <button
                className="btn-custom bg-yellow text-white fz-16 fw-700 position-relative "
                onClick={crearEmpresa}
              >
                <div className="btn-finger text-white fz-24 icon-button-start">
                  <i className="fal fa-building "></i>
                </div>
                {cargando ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                ) : (
                  "CREAR EMPRESA"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
