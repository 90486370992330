import React, { useEffect, useState, useRef } from "react";
import "./Home.css";
//Amplify
import { Auth } from "aws-amplify";

//Chart
import { Line } from "react-chartjs-2";
import { Row, Col } from "react-bootstrap";

import { API, graphqlOperation } from "aws-amplify";
import { onCreateSolicitudes } from "./../../../graphql/subscriptions";
import { listSolicitudes } from "./../../../graphql/queries";
import moment from "moment";
import { CardSolicitudes } from "../../components/CardSolicitudes";

const dataInitial = {
  labels: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ],
  datasets: [
    {
      label: "Total de Solicitudes Recibidas",
      data: [0, 0, 3, 5, 2, 3, 12, 119, 3, 5, 2, 3],
      fill: false,
      backgroundColor: "rgb(103, 209, 200)",
      borderColor: "rgba(103, 209, 200, 1)",
    },
    {
      label: "Total de Solicitudes Ejecutadas",
      data: [12, 19, 3, 53, 2, 3, 121, 129, 31, 51, 21, 31],
      fill: false,
      backgroundColor: "rgb(193, 216, 123)",
      borderColor: "rgba(193, 216, 123, 1)",
    },
  ],
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

let arrayRecibida = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 0,
  7: 0,
  8: 0,
  9: 0,
  10: 0,
  11: 0,
  12: 0,
};

let arraySolucionada = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 0,
  7: 0,
  8: 0,
  9: 0,
  10: 0,
  11: 0,
  12: 0,
};

const Home = (props) => {
  const [Solicitudes, setSolicitudes] = useState({
    solucionados: 40,
    proceso: 160,
    recibidas: 200,
    countRecibidas:0,
    countTotal:0,
    countProceso:0,
    countSolucionados:0,
  });
  const [dataState, setDataState] = useState(dataInitial);
  const [mostrar, setMostrar] = useState(false);
  const reference = useRef();

  useEffect(() => {
    validateSession();
    getInfoInicial();
    //getInfo()
  }, []);

  const getInfoInicial = async () => {
    try {
      const listadoSolicitudesData = await API.graphql({
        query: listSolicitudes,
      });
      let solicitudes = listadoSolicitudesData.data.listSolicitudes.items;
      // console.log(solicitudes)
      let countSolucionados = 0;
      let countProceso = 0;
      let countRecibidas = 0;
      let countTotal = 0;
      solicitudes.map((a) => {
        switch (a.estado) {
          case "0":
            countTotal++;
            countRecibidas++;
            let nActualRecibida = parseInt(moment(a.createdAt).format("MM"));
            arrayRecibida[nActualRecibida] = arrayRecibida[nActualRecibida] + 1;
            break;
          case "1":
            countTotal++;
            countProceso++;
            break;
          case "2":
              countSolucionados++;
              countTotal++;
            let nActualSolucion = parseInt(
              moment(a.fechaEjecucion).format("MM")
            );
            arraySolucionada[nActualSolucion] =
              arraySolucionada[nActualSolucion] + 1;
            break;
        }
      });
      //console.log('recibida',arrayRecibida)
      //console.log('countRecibidas',countRecibidas)
      //console.log('countSolucionados',countSolucionados)
      //console.log('countProceso',countProceso)

      setSolicitudes({
        recibidas: Math.round((countRecibidas * 100) / countTotal),
        proceso: Math.round((countProceso * 100) / countTotal),
        solucionados: Math.round((countSolucionados * 100) / countTotal),
        countRecibidas:countRecibidas,
        countTotal:countTotal,
        countProceso:countProceso,
        countSolucionados:countSolucionados,
      });
      updateGrafico(arrayRecibida, arraySolucionada);
    } catch (err) {
      //console.log(err)
    } finally {
      // setCargando(false)
    }
  };

  const updateGrafico = (arrayRecibida, arraySolucionada) => {
    //console.log('Array reci',arrayRecibida)
    //console.log('Array Sol',arraySolucionada)
    let actualData = dataState;
    actualData.datasets[0].data = [];
    actualData.datasets[1].data = [];

    for (var i = 1; i <= 12; i++) {
      actualData.datasets[0].data.push(arrayRecibida[i]);
      actualData.datasets[1].data.push(arraySolucionada[i]);
    }

    //actualData.datasets[0].data = [0, 100, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //console.log('actualData', actualData)
    setDataState(actualData);
    setMostrar(true);
    // console.log(ref.current.ctx.update())
    return;
    actualData.datasets[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    //console.log('Array reci',arrayRecibida)
    //console.log('Array Sol',arraySolucionada)
    //console.log('actualData',actualData)
  };

  const getInfo = () => {
    // Subscribe to creation of Todo
    const subscription = API.graphql(
      graphqlOperation(onCreateSolicitudes)
    ).subscribe({
      next: ({ value }) => console.log({ value }),
      error: (error) => console.warn(error),
    });
    //console.log(subscription)
  };

  function validateSession() {
    Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => {
        //console.log("usu", user);
        let gruposPertenece =
          user.signInUserSession.idToken.payload["cognito:groups"];
        if (!gruposPertenece.includes("admin")) {
          props.history.push("/admin/login");
        }
      })
      .catch((err) => props.history.push("/admin/login"));
  }

  const redireccion = () => {
    //console.log("ere")
  };

  return (
    <>
      <div className="background-home px-4 d-flex flex-column">
        <div className="container-card-percent">
          {/* Tarjetas de las Circular Progress Bar */}
          <CardSolicitudes
            IdPath="0"
            PathColor={"rgb(185,73,73)"}
            TrailColor={"rgb(105,28,43)"}
            NoSolicitudes={Solicitudes.countTotal}
            PercentageValue={100}
            Text={"Recibidas"}
            Mini={false}
          />

          <CardSolicitudes
            IdPath="1"
            PathColor={"rgb(244,215,0)"}
            TrailColor={"rgb(185,132,71)"}
            NoSolicitudes={Solicitudes.countProceso}
            PercentageValue={Solicitudes.proceso}
            Text={"En Proceso"}
            Mini={true}
          />

          <CardSolicitudes
            IdPath="2"
            PathColor={"rgb(193,216,123)"}
            TrailColor={"rgb(144,178,115)"}
            NoSolicitudes={Solicitudes.countSolucionados}
            PercentageValue={Solicitudes.solucionados}
            Text={"Solucionados"}
            Mini={true}
          />
          {/* Final de las Circular Progress Bar */}
        </div>

        <div className="container-card-percent py-4">
          <div className="card-percent-12">
            <Row className="py-4 mx-4 px-2">
              <div className="px-12 py-12 bg-gray br-20 shadow-interno col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <Col xs={12} md={12} className="test py-20 px-20 h-100">
                  <strong className="fz-20 pr-40">
                    {" "}
                    Flujo Mensual de Solicitudes:
                  </strong>
                  <p className="gray fw-700 fz-16">Convenciones:</p>
                  <div className="d-flex align-items-center mb-18">
                    <div className="indicador indicativo-gris"></div>
                    <p className="mb-0 fz-12 gray mx-2">
                      Total de Solicitudes Recibidas
                    </p>
                  </div>
                  <div className="d-flex align-items-center mb-18">
                    <div className="indicador indicativo-amarillo"></div>
                    <p className="mb-0 fz-12 gray mx-2">
                      Total de Solicitudes Ejecutadas
                    </p>
                  </div>
                </Col>
              </div>
              <Col
                xs={8}
                md={8}
                className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
              >
                {mostrar ? (
                  <Line data={dataState} options={options} ref={reference} />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
