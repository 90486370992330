import { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import "./Navbar.css";
import ArrowBackRounded from "@material-ui/icons/ArrowBackRounded";
import Button from "@material-ui/core/Button";
import { Redirect, Link } from "react-router-dom";

import Badge from "@material-ui/core/Badge";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const AntTabs = withStyles({
  root: {
    minWidth: 248,
  },
  indicator: {
    width: "100%",
    backgroundColor: "#67D1C8",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    width: "100%",
    height: 48,
    zIndex: 1,
    fontWeight: 700,
    fontSize: 16,
    color: "#63BAB3",
    textTransform: "none",
    minWidth: 72,
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: 16,
    paddingRight: 16,
    margin: 0,
    minHeight: "48px !important",
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "& > span": {
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "row",
    },
    "&:hover": {
      color: "#67D1C8",
      opacity: 1,
    },
    "&$selected": {
      color: "white",
      "&:hover": {
        color: "white",
      },
      "&:focus": {
        color: "white",
      },
    },
    "&:focus": {
      color: "#67D1C8",
    },
  },
  selected: {},
}))((props) => <Tab {...props}></Tab>);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  btnMenu: {
    color: "#9E9E9E",
    fontSize: 14,
    minHeight: 48,
    backgroundColor: "white",
    fontWeight: 400,
    "&:hover": {
      backgroundColor: "#f7fafc",
    },
    "& > span > i": {
      fontSize: 24,
      color: "#63bab3",
      marginRight: 12,
    },
  },
  containerlistStyles: {
    paddingTop: 38,
    paddingBottom: 30,
  },
  listStyles: {
    minWidth: 248,
    "& > div > i": {
      fontSize: 20,
      color: "#63bab3",
    },
    "&$selected": {
      color: "red",
    },
  },
  link: {
    textDecoration: "none",
  },
  hide: {
    display: "none",
  },
  drawer: {
    flexShrink: 0,
    zIndex: 3000,
  },
  drawerPaper: {},
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "center",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function LabelTab({ icon }) {
  return (
    <div className=" d-flex">
      <span>Solicitudes</span>
      
    </div>
  );
}

function IconTab({ icon }) {
  return (
    <div className="btn-finger mb-0">
      <i className={`${icon} fz-20`}></i>
    </div>
  );
}

export default function PersistentDrawerLeft() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleDrawerClose();
  };

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setTimeout(() => {
      setOpen(false);
    }, 350);
  };

  return (
    <div className={classes.root}>
      <Button
        className={`button-menu ${classes.btnMenu}`}
        onClick={handleDrawerOpen}
      >
        <i className="fal fa-bars "></i>
        <span>MENÚ</span>
      </Button>
      <Drawer
        className={classes.drawer}
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose} className="w-100 br-0">
            <ArrowBackRounded />
            <p className="mb-0">Atras</p>
          </IconButton>
        </div>

        <List className={classes.containerlistStyles}>
          <AntTabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <AntTab
              icon={<IconTab icon="fas fa-home" />}
              label="Home"
              component={Link}
              to="/admin"
            />
            <AntTab
              icon={<IconTab icon="fas fa-ticket-alt" />}
              label={<LabelTab />}
              component={Link}
              to="/admin/solicitudes"
            />
            <AntTab
              icon={<IconTab icon="fas fa-building" />}
              label="Empresas"
              component={Link}
              to="/admin/empresas"
            />
            <AntTab
              icon={<IconTab icon="fas fa-home fas fa-user-hard-hat" />}
              label="Aliados"
              component={Link}
              to="/admin/aliados"
            />
          </AntTabs>
        </List>
      </Drawer>
    </div>
  );
}
