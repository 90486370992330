import React, { Fragment, useEffect, useState } from "react";
import "./VerSolicitud.css";
// Components
import { InformacionSolicitud } from "./components/informacionSolicitud/InformacionSolicitud";
import { ArchivosAdjuntos } from "./components/archivosAdjuntos/ArchivosAdjuntos";
import { AliadosAsignados } from "./components/aliadosAsignados/AliadosAsignados";
import { SubirImagenes } from "./components/subirImagenes/SubirImagenes";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { getSolicitudes, getEmpresa } from "./../../../../graphql/queries";

const initialSoli = {
  createdAt: '',
  empresa: {
    correo: ''
  }
}
function VerSolicitud(props) {
  const [solicitud, setSolicitud] = useState(initialSoli);
  const [imagenes, setImagenes] = useState([]);
  const [actualiza, setActualiza] = useState(true);
  const [estadoGlobal, setEstadoGlobal] = useState(0);

  useEffect(async () => {
    await Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => {
        //usuario = user;
        //console.log(user.signInUserSession.accessToken.payload['cognito:groups'])
        let grupos = user.signInUserSession.accessToken.payload['cognito:groups'];
        if(grupos.includes('admin')){
          console.log("Es un admin")
        }else{
          console.log("No es un admin")
          props.history.push("/admin/login")
        }
      })
      .catch((err) => props.history.push("/admin/login"));
  }, []);

  

  return (
    <Fragment>
      <div className="wrapper-solicitud">
        <div className="pt-4 wrapper-general w-100">
          <InformacionSolicitud solicitudData={solicitud} setEstadoGlobal={setEstadoGlobal}></InformacionSolicitud>
          <AliadosAsignados solicitudData={solicitud}></AliadosAsignados>
          <ArchivosAdjuntos imagenes={imagenes} setImagenes={setImagenes} actualiza={actualiza} ></ArchivosAdjuntos> 
        </div>
        <SubirImagenes imagenes={imagenes} setImagenes={setImagenes} setActualiza={setActualiza} actualiza={actualiza} estadoGlobal={estadoGlobal}></SubirImagenes>
      </div>
    </Fragment>
  );
}

export { VerSolicitud };
