import { useState, useEffect,Fragment } from "react";

//Hojas de estilo
import "./Missolicitudescli.css";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../Missolicitudescli/Card";
//AWS
import { API, graphqlOperation, Storage, Auth } from "aws-amplify";
import { listSolicitudes, getEmpresa } from "./../../../graphql/queries";
import moment from 'moment'
const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});


//Data de prueba cards
const listCard1 = [
];

const listCard2 = [
  
];

const listCard3 = [
];

const currencies = {
  "1":"Adecuación de espacios de oficinas",
  "2":"Solicitar mobiliario",
  "3":"Servicios de instalaciones",
  "4":"Servicios de Mantenimientos",
};

const Missolicitudes = () => {

  //Estados de solicitudes
  const [enviadas, setEnviadas] = useState(listCard1);
  const [comenzadas, setComenzadas] = useState(listCard2);
  const [finalizadas, setFinalizadas] = useState(listCard3);
  const [value, setValue] = useState(2);

  useEffect(() => {
    getSolicitudes()
  }, []);

  const getSolicitudes = async () => {
    let usuario;
    let empresaId;

    //Se obtiene usuario
    await Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
    .then((user) =>usuario = user)
    .catch((err) => alert("Deberia de Iniciar Sesion"));    
    //console.log('usK', usuario.username)
    //Obtener Sede
    /*const solicitudes = await API.graphql(
      graphqlOperation(listSolicitudes, { usuario: usuario.username })
    );*/
    const initFiltro = {}
    initFiltro.usuario = {};
    initFiltro.usuario.eq = usuario.username;
    const solicitudes = await API.graphql({
      query: listSolicitudes,
      variables: {
        filter:initFiltro
      }
    });
    //console.log('SolK', solicitudes.data.listSolicitudes)
    const listCard1 =[]
    const listCard2 =[]
    const listCard3 =[]
    solicitudes.data.listSolicitudes.items.map((sol) => {
        //console.log("sol", sol)
        switch (sol.estado) {
          case '0':
            listCard1.push({
              id: sol.id,
              title: currencies[sol.queNecesitas],
              estado: "Enviada",
              fecha: moment(sol.createdAt).format('YYYY/MM/DD'),
              hora: moment(sol.createdAt).format('hh:mm:ss a'),
              descripcion: sol.descripcion,
            });
            break;
          case '1':
            listCard2.push({
              id: sol.id,
              title: currencies[sol.queNecesitas],
              estado: "En Proceso   ",
              fecha: moment(sol.createdAt).format('YYYY/MM/DD, hh:mm:ss a'),
              descripcion: sol.descripcion,
            });
          break;
          case '2':
            listCard3.push({
              id: sol.id,
              title: currencies[sol.queNecesitas],
              estado: "Ejecutada",
              fecha: moment(sol.createdAt).format('YYYY/MM/DD, hh:mm:ss a'),
              descripcion: sol.descripcion,
            });
          break;
        }
    });
    setEnviadas(listCard1)
    setComenzadas(listCard2)
    setFinalizadas(listCard3)
    /*console.log('list1', listCard1)
    console.log('list2', listCard2)
    console.log('list3', listCard3)*/
    


  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const classes = useStyles();

  return (
    <Fragment>
        <div className="d-flex justify-content-center align-items-center box-shadow bg-white mstitulo">
          <h2 className="color-dark-1 fs-24 fw-700 letter-s-1-2">
            Mis solicitudes
          </h2>
        </div>
        <div className="bg-grey-3 h-100-vh mt-2">
          
          <div className="navegation">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Enviadas
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  En proceso
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-contact-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-contact"
                  type="button"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                >
                  Ejecutadas
                </button>
              </li>
            </ul>
          </div>

          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              {enviadas.map((data, index) => (
                <Card key={index}
                  titulo={data.title}
                  subtitulo={data.subtitle}
                  estado={data.estado}
                  fecha={data.fecha}
                  hora={data.hora}
                  descripcion={data.descripcion}
                  id={data.id}
                />
              ))}
            </div>
  
            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
              {comenzadas.map((data, index) => (
                <Card key={index}
                  titulo={data.title}
                  subtitulo={data.subtitle}
                  estado={data.estado}
                  fecha={data.fecha}
                  hora={data.hora}
                  descripcion={data.descripcion}
                  id={data.id}
                />
              ))}
            </div>
                
            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                {finalizadas.map((data, index) => (
                  <Card key={index}
                    titulo={data.title}
                    subtitulo={data.subtitle}
                    estado={data.estado}
                    fecha={data.fecha}
                    hora={data.hora}
                    descripcion={data.descripcion}
                    id={data.id}
                  />
                ))}
            </div>
          </div>
        </div>
    </Fragment>
  );
};

export default Missolicitudes;