import React, { useState, Fragment, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { Link } from "react-router-dom";

import "./List.css";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
//import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { listEmpresas } from "./../../../../../graphql/queries";
import { API, Auth } from "aws-amplify";
import { updateEmpresa } from "../../../../../graphql/mutations";
import { useHistory } from "react-router-dom";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#FFFFFF",
    color: "#707070",
    fontWeight: "700",
    height: "56px",
    background: "#FFFFFF",
    fontSize: 16,
    border: 0,
  },
  body: {
    fontSize: 16,
    color: "#707070",
    border: 0,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F5FCFF",
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    width: "100%",
    boxShadow: "0",
  },
  tableHead: {
    padding: 72,
  },
  tableBody: {
    padding: 72,
  },
  tdId: {
    paddingLeft: 72,
  },
  container: {
    maxHeight: 700,
    minHeight: 700,
  },
  buttonEdit: {
    height: 48,
    width: 48,
    color: "#63bab3",
    fontSize: 24,
  },
  buttonDelete: {
    height: 48,
    width: 48,
    color: "#ff5858",
    fontSize: 24,
  },
  buttonLink: {
    textDecoration: "none",
  },
});
const initFiltro = {}
initFiltro.estado = {};
initFiltro.estado.eq = true;
export default function List(props) {
  // Buscador

  const [filtro, setFiltro] = useState(initFiltro);
  const [nextToken, setNextToken] = useState(undefined);
  const [nextNextToken, setNextNextToken] = useState();
  const [previousTokens, setPreviousTokens] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [redirigir, setRedirigir] = useState(false);
  const [empresas, setEmpresas] = useState([]);
  const limit = 10;
  const [empresaSearch, setEmpresaSearch] = useState("");

  useEffect(() => { 
    validateSession()   
    getList();
  }, [nextToken, filtro]);

  function validateSession() {
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
    .then((user) => {
      console.log("Usuario Conectado");
    })
    .catch((err) =>setRedirigir(true) );
  }

  async function getList() {
    setCargando(true);
    try {
      const listadoEmpresasData = await API.graphql({
        query: listEmpresas,
        variables: {
          limit,
          nextToken,
          filter: filtro,
        },
      });
      let dataItem = listadoEmpresasData.data.listEmpresas.items;
      setEmpresas(dataItem);
      setNextNextToken(listadoEmpresasData.data.listEmpresas.nextToken);
    } catch (err) {
      console.log(err);
    } finally {
      setCargando(false);
    }
  }

  async function nextPage() {
    setPreviousTokens((prev) => [...prev, nextToken]);
    setNextToken(nextNextToken);
    setNextNextToken(null);
  }

  function prevPage() {
    setNextToken(previousTokens.pop());
    setPreviousTokens([...previousTokens]);
    setNextNextToken(null);
  }

  function search() {
    console.log("a buscar", empresaSearch);
    let filtrar = undefined;

    if (empresaSearch !== undefined && empresaSearch !== "") {
      filtrar = filtrar === undefined ? {} : filtrar;
      filtrar.razon = {};
      filtrar.razon.contains = empresaSearch.toUpperCase();
    }

    setFiltro(filtrar);
  }

  async function deleteEmpresa(id) {
    console.log(id);
    let sendData = {
      query: updateEmpresa,
      variables: {
        input: {
          id: id,
          estado: false
        },
      },
    };
    await API.graphql(sendData)
      .then((result) => {
        console.log("Se Cambia de estado", result);
        getList();
      })
      .catch((err) => {
        console.log("error sede", err);
        alert("Ocurrio un error");
      });
  }

  const classes = useStyles();
  let historia = useHistory();
  if(redirigir){
    historia.push("/admin/login")
  }

  return (
    <Fragment>
      <div className="w-100 box-shadow position-relative header-business">
        <div className="wrapper-general pb-28 pt-32">
          <div className="row mx-auto mb-32">
            <div className="col-lg-3 col-md-6 d-flex align-items-center px-0">
              <Link to="/admin" className="btn-finger fz-24 text-blue-ligth">
                <i className="fal fa-chevron-left"></i>
              </Link>
              <h2 className="text-dark-1 fz-28 fw-700 title-business">
                Empresas
              </h2>
            </div>
            <div className="col-lg-3 col-md-6 px-0">
              <Link
                to="/admin/empresas/crear-empresa"
                className="button-create-business justify-content-start fw-700"
              >
                <button
                  to="/admin/empresas/crear-empresa"
                  className="btn-finger  text-blue-ligth"
                >
                  <i className="fal fa-plus fz-24"></i>
                </button>
                Crear Nueva Empresa
              </Link>
            </div>
          </div>
          {/* <div className="d-flex align-items-center ">
          </div> */}
          <div className="row d-flex align-items-center">
            <div className="mb-2 col-md-8 col-lg-8 col-xl-10">
              <TextField
                id="search"
                label="Criterio de búsqueda"
                className="text-fiel-custom-blue"
                value={empresaSearch}
                onChange={(event) => setEmpresaSearch(event.target.value)}
              />
            </div>
            <div className="mb-2 col-md-4 col-lg-4 col-xl-2">
              <button
                className="btn-custom d-flex justify-content-center bg-yellow text-white box-shadow-1 fw-700 position-relative position-relative"
                onClick={search}
              >
                <div className="btn-finger text-white fz-24 icon-button-start">
                  <i className="fal fa-search"></i>
                </div>
                {cargando ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                ) : (
                  "BUSCAR"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100  px-0 position-relative list-business">
        <TableContainer component={Paper} className={classes.container}>
          <Table
            className={classes.table}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead className={classes.tableHead}>
              <TableRow>
                <StyledTableCell className={classes.tdId}>
                  ID Empresa
                </StyledTableCell>
                <StyledTableCell>Razón social</StyledTableCell>
                <StyledTableCell>Nit</StyledTableCell>
                <StyledTableCell>Ciudad</StyledTableCell>
                <StyledTableCell>Teléfono</StyledTableCell>
                <StyledTableCell>Dirección</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {empresas.map((row, b) => (
                <StyledTableRow key={b}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    className={classes.tdId}
                  >
                    {b + 1}
                  </StyledTableCell>
                  <StyledTableCell>{row.razon}</StyledTableCell>
                  <StyledTableCell>{row.nit}</StyledTableCell>
                  <StyledTableCell>{row.ciudadPrincipal}</StyledTableCell>
                  <StyledTableCell>{row.telefono}</StyledTableCell>
                  <StyledTableCell>{row.direccion}</StyledTableCell>
                  <StyledTableCell>
                    <div className="d-flex justify-content-center align-items-center">
                      <Link
                        className={classes.buttonLink}
                        to={`/admin/empresas/informacion-de-empresa/?id=${row.id}`}
                      >
                        <Button className={classes.buttonEdit}>
                          <i className="fal fa-eye fz-24"></i>
                        </Button>
                      </Link>
                      <Button
                        className={classes.buttonDelete}
                        onClick={(e) => {
                          deleteEmpresa(row.id);
                        }}
                      >
                        <i className="fal fa-trash-alt fz-24"></i>
                      </Button>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className="wrapper-pagination mb-64">
        <div className="pagination-button  color-primary" onClick={prevPage}>
          <div className="button-finger">
            <i className="fal fa-chevron-left fz-24"></i>
          </div>
        </div>
        <div className="pagination-numeric ">
          <span>1 de 10</span>
        </div>
        <div className="pagination-button color-primary" onClick={nextPage}>
          <div className="button-finger">
            <i className="fal fa-chevron-right  fz-24"></i>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
