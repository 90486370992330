import { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";

import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Backdrop from "@material-ui/core/Backdrop";
import citys from "./../../../../../../ciudades.json";



const useStyles = makeStyles((theme) => ({
  checkedCustom: {
    color: "#63BAB3",
    "&$checked": {
      color: "#63BAB3",
      backgroundColor: "#63BAB3",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: "blur(4px)",
  },
  paper: {
    backgroundColor: "white",
    borderRadius: 12,
    width: 472,
    paddingTop: 32,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 32,
  },
}));

const form = {
  ciudad: "",
};

function ListCitys({
  ciudadesAliados,
  setCiudadesAliados,
  setCheckedCobertura,
  checkedCobertura,
}) {
  const classes = useStyles();

  const [checked, setChecked] = useState(true);
  const [formState, setFormState] = useState(form);

  function setSelect(value) {
    setFormState({ ...formState, ciudad: value });
  }

  const addCity = () => {
    setFormState({ ...formState, submit: true });
    if (formState.ciudad) {
      let arrayViejo = ciudadesAliados;
      arrayViejo.push(formState.ciudad);
      setCiudadesAliados(arrayViejo);
      handleClose();
    }
  };

  const deleteCity = (value) => {
    //citys.push(value);
    //citys.sort();
    setCiudadesAliados(ciudadesAliados.filter((item) => item !== value));
    //saveCitys(cities);
  };

  const handleChangeChecked = (event) => {
    setCheckedCobertura(!checkedCobertura);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setFormState(form);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // definir el State del siderBar
  let [siderbar, saveChangeSiderbar] = useState(false);

  return (
    <div className="position-relative" style={{ zIndex: "1000" }}>
      <div
        className={`siderBarConst-city-container ${siderbar ? "active" : ""}`}
      >
        <div className="overflow-auto siderBarConst-city">
          <div className="siderBarConst-city-list">
            {ciudadesAliados.map((value, index) => (
              <div className="siderBarConst-city-list-item" key={index}>
                <div className="d-flex align-items-center border-bottom-gray">
                  <div className="btn-finger text-blue-ligth fz-24">
                    <i class="fal fa-building"></i>
                  </div>
                  <p className="text-dark-1 mb-0 fw-700">Ciudad</p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p
                    className="text-dark-1 mb-0 fw-700 fz-20 text-truncate"
                    style={{ maxWidth: 284 }}
                  >
                    {value}
                  </p>
                  <div
                    className="btn-finger text-red fz-24"
                    onClick={() => {
                      deleteCity(value);
                    }}
                  >
                    <i class="far fa-trash-alt"></i>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="siderBarConst-city-footer">
          <div className="row mx-auto">
            <div className="col-md-8 col-sm-12 mb-20 mx-auto">
              <button
                className="btn-custom bg-yellow text-white fz-16 fw-700 position-relative "
                onClick={handleOpen}
              >
                <div className="btn-finger text-white fz-24 icon-button-start">
                  <i class="fal fa-building"></i>
                </div>
                AGREGAR CIUDAD
              </button>
            </div>
            <div className="col-md-8 col-sm-12 mb-2 mx-auto">
              <div className="group-checkbox">
                <input
                  type="checkbox"
                  id="cobertura_general"
                  className="group-checkbox-input"
                  checked={checkedCobertura}
                  onChange={handleChangeChecked}
                  name="checkedCoberturaGeneralNacional"
                />
                <label
                  className="group-checkbox-label"
                  htmlFor="cobertura_general"
                >
                  <i class="far fa-check"></i>
                </label>
                <span className="text-dark-1">Cobertura general nacional</span>
              </div>

            </div>
          </div>
        </div>
        <div
          className="button-float-siderbar"
          onClick={() => {
            saveChangeSiderbar(!siderbar);
          }}
        >
          <i class="far fa-chevron-left"></i>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 className="fz-28 fw-700 text-dark-1 text-center mb-20">
              ¿Qué ciudad quiere asignar?
            </h2>
            <div className="row mx-auto">
              <div className="col-md-12 mb-48">
                <Autocomplete
                  id="Aliado"
                  options={citys}
                  getOptionLabel={(option) => option}
                  autoComplete={false}
                  onChange={(event, newValue) => {
                    setSelect(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Ciudad."
                      className="text-fiel-custom-blue"
                      autoComplete="chrome-off"
                    />
                  )}
                />
              </div>
            </div>
            <div className="row mx-auto">
              <div className="col-md-6 mb-2">
                <button
                  onClick={handleClose}
                  className="btn-custom bg-blue-dark text-white fz-16 fw-700 position-relative "
                >
                  <div className="btn-finger text-white fz-24 icon-button-start">
                    <i className="fal fa-times"></i>
                  </div>
                  CANCELAR
                </button>
              </div>
              <div className="col-md-6 mb-2">
                <button
                  className="btn-custom bg-yellow text-white fz-16 fw-700 position-relative "
                  onClick={addCity}
                >
                  ASIGNAR CIUDAD
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export { ListCitys };
