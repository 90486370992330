import { useState, useEffect,Fragment } from "react";
import { Link } from "react-router-dom";
import "./Homecli.css";
import finsociallogo from '../../../assets/images/finsocial.PNG';
import { API, graphqlOperation, Storage, Auth } from "aws-amplify";
import { getEmpresa } from "./../../../graphql/queries";

const Homecli = () => {
  const [logo, setLogo] = useState(finsociallogo);

  useEffect(() => {
    getLogo()
  }, []);

  const getLogo = async () => {
    let usuario;
    await Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => {
        usuario = user;
      })
      .catch((err) => alert("Deberia de Iniciar Sesion"));
      const empresa = await API.graphql(
        graphqlOperation(getEmpresa, { id: usuario.attributes['custom:empresa'] })
      );
      /*console.log(usuario.attributes['custom:empresa']);
      console.log('empresa',empresa);*/
      if(empresa.data.getEmpresa.logo !== ''){
        //console.log(await Storage.get(empresa.data.getEmpresa.logo))
        setLogo(await Storage.get(empresa.data.getEmpresa.logo));
      }      
  }

  return (
    <Fragment>
          <div className="hc1">
            <div className="h-160-r bg-blue-1"></div>
          </div>

          <div className="d-flex justify-content-center mb-5">
            <div className="pos-relative d-flex justify-content-center align-items-center bg-blue-2 w-200-px h-200-px br-100-px bs-3 br-100-p">
              <div className="bg-white-0 w-180-px h-180-px br-100-p">
                <div className="pos-relative d-flex justify-content-center align-items-center w-180-px h-180-px br-100-p">
                  <img width="130px" src={ logo } alt={ logo } />
                </div>
              </div>
            </div>
          </div>

          <div className="container-home">
              <h2 className="text-center fs-28 fw-700 letter-s-0 mb-12 text-dark-2">
                ¡Hola, Finsocial!
              </h2>
              <h3 className="text-center fs-18 letter-s-0 color-blue-dark-1 mb-28 fw-700">
                ¿Qué deseas hacer el día hoy?
              </h3>
              <Link className="text-decoration-0" to="/cliente/solicitud" >
                <button
                  type="button"
                  className="btn-custom d-flex justify-content-center bg-yellow text-white box-shadow-1 fw-700  mb-12"
                >
                  NUEVA SOLICITUD
                </button>
              </Link>
              <Link className="text-decoration-0" to="/cliente/solicitudes">
                <button
                  type="button"
                  className="btn-custom d-flex justify-content-center bg-yellow text-white box-shadow-1 fw-700  mb-12"
                >
                  MIS SOLICITUDES
                </button>
              </Link>

              <Link className="text-decoration-0" to="/cliente/preferencias">
                <button
                  type="button"
                  className="btn-custom d-flex justify-content-center bg-yellow text-white box-shadow-1 fw-700  mb-28"
                >
                  MIS PREFERENCIAS
                </button>
              </Link>

              <Link className="text-decoration-0" to="/cliente/login">
                <button
                  type="button"
                  className="btn-custom d-flex justify-content-center bg-blue-dark text-white box-shadow-1 fw-700  mb-36"
                >
                  CERRAR SESION
                </button>
              </Link>
          </div>
    </Fragment>
  );
};

export default Homecli;