import React from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "../admin/home/Home.css";

export const CardSolicitudes = ({
  IdPath,
  PathColor,
  TrailColor,
  NoSolicitudes,
  PercentageValue,
  Text,
  Mini,
}) => {
  return (
    <div className="card-percent">
      <div className="box mt-4">
        <div className="bg-white br-50 shadows mx-3  mb-3 relative">
          {Mini ? <div className="shadows-father" /> : ""}
          <div className="percent px-3 py-3">
            {Mini ? (
              <div className="mini">
                <CircularProgressbarWithChildren
                  value={100}
                  styles={buildStyles({
                    rotation: 0,
                    strokeLinecap: "butt",
                    pathTransitionDuration: 0.5,
                    pathColor: PathColor,
                    trailColor: TrailColor,
                    strokeWidth: 10,
                  })}
                >
                  <div>{`${PercentageValue}%`} </div>
                </CircularProgressbarWithChildren>
              </div>
            ) : (
              ""
            )}

            <a href={`/admin/solicitudes/?t=${IdPath}`}>
              <CircularProgressbarWithChildren
                value={PercentageValue}
                styles={buildStyles({
                  rotation: 0,
                  strokeLinecap: "butt",
                  pathTransitionDuration: 0.5,
                  pathColor: PathColor,
                  trailColor: TrailColor,
                  strokeWidth: 80,
                })}
              >
                <div className="number flex-column">
                  <h2 className="mb-0">{NoSolicitudes}</h2>
                  <p className="fz-14 text-center gray mb-0 fw-700">
                    Solicitudes
                  </p>
                </div>
              </CircularProgressbarWithChildren>
            </a>
          </div>
        </div>
        <h2 className="text text-black mb-3">
          <strong>Total Solicitudes</strong> <br /> {Text}
        </h2>
      </div>
    </div>
  );
};
