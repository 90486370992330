/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:396691ec-7b59-4137-b674-a800d2742b7d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_jhVtBG8E1",
    "aws_user_pools_web_client_id": "1nd4srr7rj97cgq90mri7d2ka3",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://gaczdunuxjh2xbrebz77kshgza.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-zhtp7mj3anbhbd6mqua5bejt6q",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://o6svz415mb.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "hoomapp6debaf05c2374fb9b4eb339c33d83ba491949-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "hoomapp-20211010095720-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2dq05u62hog6l.cloudfront.net"
};


export default awsmobile;
