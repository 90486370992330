import { Fragment, useState } from "react";

import "./CrearAliados.css";

// Components
import { ListCitys } from "./components/listCitys/ListCitys";
import { FormAliado } from "./components/formAliado/FormAliado";



function CrearAliados() {
  // Definiendo el state de la lista y el formulario si va a editar o crear
  const [ciudadesAliados, setCiudadesAliados] = useState([]);
  const [checkedCobertura, setCheckedCobertura] = useState(false); 
  return (
    <Fragment>
      <div className="wrapper-crear-aliado">
        <FormAliado ciudadesAliados={ciudadesAliados} checkedCobertura={checkedCobertura} setCiudadesAliados={setCiudadesAliados} setCheckedCobertura={setCheckedCobertura}  /> 
        <ListCitys ciudadesAliados={ciudadesAliados}  checkedCobertura={checkedCobertura} setCiudadesAliados={setCiudadesAliados} setCheckedCobertura={setCheckedCobertura} />
      </div>
    </Fragment>
  );
}

export { CrearAliados };
