import { useState, useEffect,Fragment } from "react";
import { Link } from "react-router-dom";
import "./Solicitudcli.css";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import logo from '../../../assets/images/logohoom1.png';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

//listas
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";

import InputAdornment from "@material-ui/core/InputAdornment";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

//AWS
import { API, graphqlOperation, Auth, Storage } from "aws-amplify";
import { createSolicitudes, createArchivosSolicitud } from "./../../../graphql/mutations";
import {  getEmpresa } from "./../../../graphql/queries";

//Modal
import Fade from "@material-ui/core/Fade";
import Modal from '@material-ui/core/Modal';

//Data de prueba combobox
const currencies = [
  {
    value: "1",
    label: "Adecuación de espacios de oficinas",
  },
  {
    value: "2",
    label: "Solicitar mobiliario",
  },
  {
    value: "3",
    label: "Servicios de instalaciones",
  },
  {
    value: "4",
    label: "Servicios de Mantenimientos",
  },
];



const initalSol = {
  queNecesitas: "",
  cualEsTuSede: "",
  enQueCiudadEstaTuSede: "",
  descripcion: "",
  estado: 1,
  usuario: "eduardo",
};

const initialSolcitud = {
  queNecesitas: "",
  cualEsTuSede: "",
  enQueCiudadEstaTuSede: "",
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },

    "& > *": {
      margin: theme.spacing(1),
    },
  },

  input: {
    display: "none",
  },

  TextFieldCustom: {
    width: "100%",
    margin: "15px 0 15px 0",
    "& > label": {
      color: "63BAB3#",
    },
    "& > div::before": {
      borderBottom: "1px solid #63BAB3",
    },
  },

  TextFieldMultinea: {
    width: "100%",
    border: "1px solid #63BAB3",
    marginBottom: 12,
    borderRadius: 11,
    "& > div::before": {
      display: "none",
    },
    "& > div::after": {
      display: "none",
    },
    "& > label.MuiInputLabel-shrink": {
      transform: "translate(0, 16px) scale(0.75)",
    },
  },
}));

const StyledItemText = withStyles((theme) => ({
  root: {
    color: "#707070",
  },
  primary: {
    color: "#7DB3B7",
    fontSize: "12px",
  },
  secondary: {
    color: "#707070",
    fontSize: "14px",
  },
}))((props) => <ListItemText {...props} />);


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const listImages = [];

const Solicitudcli = () => {
  const classes = useStyles();
  const [comboBox, setComboBox] = useState(initialSolcitud);
  const [images, setImages] = useState(listImages);
  const [files, setFiles] = useState(listImages);
  const [formState, setFormState] = useState(initalSol);
  const [cargando, setCargando] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [usuarioState, setUsuarioState] = useState({});
  const [sedesState, setSedesState] = useState([]);
  const [ciudadesState, setCiudadesState] = useState([]);

  useEffect(() => {
    getSedes();
    setSedesState([])
    setCiudadesState([])
  }, []);

  const getSedes = async () => {   
    
    let usuario
    await Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
    .then((user) => {
      setUsuarioState(user)
      usuario = user;
    })
    .catch((err) => alert("Deberia de Iniciar Sesion"));
    
    let empresa = usuario.attributes['custom:empresa'];
    //Get Sede principal de la empresa 
    
    const empresaData = await API.graphql(
      graphqlOperation(getEmpresa, { id: empresa })
    ).then(r => {
      setSedesState([])
      setCiudadesState([])
      const currencies2= []
      const currencies3= []
      //setEmpresaState(r.data.getEmpresa);      
      r.data.getEmpresa.sedes.items.sort().map(s => {
        if(s.estado == true){
          currencies2.push({
            value: s.ciudad,
            label: s.ciudad,
          })
  
          currencies3.sort().push({
            value: s.id,
            label: s.nombre,
          });
        }        
      });
      //console.log('sedeC', currencies2.sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0)))
      setCiudadesState(currencies2.sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0)))
      setSedesState(currencies3.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)))      
    });
  }

  const handleChange = (key, value) => {
    setComboBox({ ...comboBox, [key]: value });
  };

  function handleChangeimages({ target }) {
    const file = target.files[0];
    const url = URL.createObjectURL(file);
    let img = {
      file,
      url,
      name: file.name,
    };
    setImages([...images, img]);
  }

  function handleChangeFiles({ target }) {
    const file = target.files[0];   

    let extension = file.name
      .substring(file.name.lastIndexOf(".") + 1)
      .toLowerCase();
    let filesData = {
      file,
      extension,
      name: file.name,
    };
    setFiles([...files, filesData]);
  }

  function deleteImages(index) {
    setImages(images.filter((data, indice) => indice !== index));
    console.log(images);
  }  
  function deleteFiles(index) {
    setFiles(files.filter((data, indice) => indice !== index));
    console.log(files);
  }  

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  async function crearSolicitud() { 

    setCargando(true);
    let usuario;
    await Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => {
        usuario = user;
      })
      .catch((err) => alert("Deberia de Iniciar Sesion"));

      
    //Iniciamos Validaciones de Formulario
    if (
      formState.queNecesitas === "" ||
      formState.cualEsTuSede === "" || 
      formState.enQueCiudadEstaTuSede === "" || 
      formState.descripcion === ""
    ) {
      setCargando(false);
      alert("Campos Vacios");
      return;
    }

    
    //Se crea la solicitud
    let sendDataSolicitud = {
      input: {
        queNecesitas: formState.queNecesitas,
        descripcion: formState.descripcion,
        ciudad_sede: formState.enQueCiudadEstaTuSede,
        sede: formState.cualEsTuSede,
        estado: 0,
        usuario: usuario.username,
        empresaID: usuario.attributes['custom:empresa']
      },
    };

    let solicitudCreada;
    await API.graphql(graphqlOperation(createSolicitudes, sendDataSolicitud))
      .then((result) => {
        solicitudCreada = result.data.createSolicitudes;
        console.log("Se crea Solicitud", solicitudCreada);
      })
      .catch((err) => {
        console.log("Error de la operacion de creacion ", err);
        setCargando(false);
      });

      //Se Suben las Imagenes
    const resp = await Promise.all(
      
      images.map(async (archivo, b) => {
        let numB = Math.floor(Math.random() * 100000)
        let nombreImage;
        await Storage.put(numB + archivo.name, archivo.file, {
        })
          .then((result) => {
            console.log('se sube',result.key);
            nombreImage = result.key
          })
          .catch((err) => {
            console.log(err);
            setCargando(false);
          });
          //Se registran los archivos
          let sendDataSolicitudArchivos = {
            input: {
              solicitudID: solicitudCreada.id,
              nombre: nombreImage,
              imagen: true
            },
          };
          await API.graphql(graphqlOperation(createArchivosSolicitud, sendDataSolicitudArchivos))
          .then((result) => {
            //console.log("Se crea SolicitudArchivos", result);
          })
          .catch((err) => {
            console.log("Error de la operacion de creacion ", err);
            setCargando(false);
          });
      })
    );

    const respFiles = await Promise.all(
      files.map(async (archivo, b) => {
        let numB = Math.floor(Math.random() * 100000)
        let nombreFile;
        await Storage.put(numB + archivo.name, archivo.file, {
        })
          .then((result) => {
            //console.log('se sube',result.key);
            nombreFile = result.key
          })
          .catch((err) => {
            console.log(err);
            setCargando(false);
          });
          //Se registran los archivos
          let sendDataSolicitudArchivos = {
            input: {
              solicitudID: solicitudCreada.id,
              nombre: nombreFile,
              imagen: false
            },
          };
          await API.graphql(graphqlOperation(createArchivosSolicitud, sendDataSolicitudArchivos))
          .then((result) => {
            //console.log("Se crea SolicitudArchivos", result);
          })
          .catch((err) => {
            console.log("Error de la operacion de creacion ", err);
            setCargando(false);
          });
      })
    );
      setImages([])
      setFiles([])
      setFormState(initalSol);
      setCargando(false); 
      setOpen(true)
  }

  return (
    <Fragment>
      <div className="container-home">
        <div className="d-flex justify-content-center flex-column">
            <h2 className="text-dark-2 fs-24 fw-700 letter-s-1-2 mt-40 mb-36">
              Crear una nueva solicitud
            </h2>

            <form className="f-flex" noValidate autoComplete="off">
              <TextField
                id=""
                select
                className="text-fiel-custom-blue mb-16"
                label="¿Que necesitas?"
                value={formState.queNecesitas}
                onChange={(event) => setInput("queNecesitas", event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <KeyboardArrowDownIcon />
                    </InputAdornment>
                  ),
                }}
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id=""
                className={classes.TextFieldMultinea}
                label="Descripción de la solicitud"
                onChange={(event) => setInput("descripcion", event.target.value)}
                multiline
                rows={4}
                value={formState.descripcion}
              />

              <TextField
                id=""
                select
                className="text-fiel-custom-blue mb-12"
                label="¿En que ciudad esta tu sede?"
                value={formState.enQueCiudadEstaTuSede}
                onChange={(event) => setInput("enQueCiudadEstaTuSede", event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <KeyboardArrowDownIcon />
                    </InputAdornment>
                  ),
                }}
              >
                {ciudadesState.sort().map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id=""
                select
                className="text-fiel-custom-blue mb-12"
                label="¿Cual es tu sede?"
                value={formState.cualEsTuSede}
                onChange={(event) => setInput("cualEsTuSede", event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <KeyboardArrowDownIcon />
                    </InputAdornment>
                  ),
                }}
              >
                {sedesState.sort().map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </form>

            <List id="list-file">
              {images.map((item, index) => (
                <ListItem key={index}>
                  <ListItemAvatar>
                    <Avatar
                      className="br-0 w-56 h-56"
                      alt={item.name}
                      src={item.url}
                    />
                  </ListItemAvatar>

                  <StyledItemText
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title={item.name}
                    primary="Imagen Adjunta"
                    secondary={item.name}
                  />

                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => deleteImages(index)}
                    >
                      <i className="far fa-trash-alt text-red fz-24"></i>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
              {files.map((item, index) => (
                <ListItem key={index}>
                  <ListItemAvatar>
                    <Avatar
                      className="br-0 w-56 h-56"
                      alt={item.name}
                      src={item.url}
                    />
                  </ListItemAvatar>

                  <StyledItemText
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title={item.name}
                    primary="Imagen Adjunta"
                    secondary={item.name}
                  />

                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => deleteFiles(index)}
                    >
                      <i className="far fa-trash-alt text-red fz-24"></i>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>

            <div className="d-flex align-items-center mb-20">
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleChangeimages}
              />

              <label htmlFor="contained-button-file">
                <IconButton
                  component="span"
                  className={classes.margin}
                  size="small"
                >
                  <i className="far fa-image color-green-1"></i>
                </IconButton>
              </label>

              <div className="br-r1s h-32-px mx-5-px" />
              <input
                accept=".pdf"
                className={classes.input}
                id="icon-button-file"
                type="file"
                onChange={handleChangeFiles}
              />

              <label htmlFor="icon-button-file">
                <IconButton
                  component="span"
                  className={classes.margin}
                  size="small"
                >
                  <i className="far fa-paperclip color-green-1"></i>
                </IconButton>
              </label>

              <div className="br-r1s h-32-px mx-5-px" />
            </div>

            <Link to="#">
              <button
                type="button"
                className="btn bg-yellow-0 bs-2 br-30 color-white-1 fs-14 w-100-p mh-48 py-13 px-3 mb-20"
                onClick={crearSolicitud}
                disabled={cargando}
              >
                {cargando ? (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                ) : (
                  "ENVIAR"
                )}
              </button>
            </Link>
            <Link to="home" style={{ display: "block", width: "100%" }}>
              <button
                type="button"
                className="btn bg-grey-0 bs-2 br-30 color-white-1 fs-14 w-100-p py-13 px-3 fw-bold mh-48 mb-20"
              >
                ATRAS
              </button>
            </Link>
        </div>
      </div>

      <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
        >
          <Fade in={open}>
            <div className="modal1">
              <div className="">
                <div className="bg-white-0 w-100 h-103 d-flex justify-content-center align-items-center flex-column">
                  <img src={logo} alt='logo' className="logo"/>
                </div>
                <h5 className="text-center dark-2-color letter-s-1">¡Solicitud enviada!</h5>
                <p className="text-center text-dark-1">Muy pronto nos pondremos en contacto contigo</p>
                
                <Link to="home" style={{ display: "block", width: "100%" }}>
                  <button type="button" className="bg-yellow-0 fw-bold btn w-100-p fs-14  br-30 bs-2 py-13 px-3 color-white-1 mh-48">FINALIZAR</button>
                </Link>

                <Link to="solicitudes" style={{ display: "block", width: "100%" }}>
                  <button
                    type="button"
                    className="btn bg-grey-0 bs-2 br-30 color-white-1 fs-14 w-100-p py-13 px-3 mt-28 fw-bold mh-48"
                  >
                    MIS SOLICITUDES
                  </button>
                </Link>
                
              </div>
            </div>
          </Fade>
        </Modal>
    </Fragment>
  );
};
export default Solicitudcli;