import { makeStyles } from "@material-ui/core/styles";
import logo from '../../../assets/images/logohoom1.png';
import Button from "@material-ui/core/Button";
import PersistentDrawerLeft from "./Sidebar";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import "./Navbar.css";

const useStyles = makeStyles((theme) => ({
  btnMenu: {
    color: "#9E9E9E",
    fontSize: 14,
    minHeight: 48,
    backgroundColor: "white",
    fontWeight: 400,
    "&:hover": {
      backgroundColor: "#f7fafc",
    },
    "& > span > i": {
      fontSize: 24,
      color: "#63bab3",
      marginRight: 12,
    },
  },
}));

function Navbar (props)  {
  const classes = useStyles();

  async function signOut() {
    try {
      await Auth.signOut();
      localStorage.clear();
      window.location.reload(false);
    } catch (error) {}
  }

  


  return (
    <>
      <div className="navbar py-0">
        <div className="navbar-container">
          <PersistentDrawerLeft />
          <Link to="/admin" className="navbar-logo">
            <img src={logo} className ="navbar-img"></img>
          </Link>
          <Button
            className={`button-cerrar-sesion ${classes.btnMenu}`}
            onClick={signOut}
          >
            <i className="fal fa-power-off"></i>
            <span>CERRAR SESIÓN</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default Navbar;
